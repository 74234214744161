<div class="app-contract-component">
  <table>
    <tr>
      <td class="content-info">
        <div class="header">
          <div class="item w1">
            <div class="title">契約コード</div>
            <div class="value">{{contract_content.contract_summary.contract_code}}</div>
          </div>
          <div class="item w1">
            <div class="title">契約状況</div>
            <div class="value">
              <button mat-icon-button class="edit_icon"
                (click)="onEditContractStatus(contract_content.contract_summary)">
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>
              {{ contractStatusTitleFromStatus(contract_content.contract_summary.status) }}
            </div>
          </div>
          <div *ngIf="isSubscriptionFiber" class="item w1">
            <div class="title">[FIBER] 申し込みリンク</div>
            <div class="value"><a
                href="{{fiber_order_root}}/fiber-order?order={{contract_content.contract_summary.contract_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="isSubscriptionFiber" class="item w1">
            <div class="title">[FIBER] スケジュール(顧客用)</div>
            <div class="value"><a
                href="{{fiber_order_root}}/installation-schedule-form?contract_hash={{contract_content.contract_summary.contract_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="isSubscriptionFiber" class="item w1">
            <div class="title">[FIBER] スケジュール(管理)</div>
            <div class="value"><a
                href="/operation/fiber_schedule?contract_product={{fiberProductId}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="!isSubscriptionFiber" class="item w1">
            <div class="title">[SIM/WIFI] Step2リンク</div>
            <div class="value"><a
                href="{{order_form_root}}/step2?order={{contract_content.contract_summary.contract_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="!isSubscriptionFiber" class="item w1">
            <div class="title">[SIM/WIFI] ドキュメント督促リンク</div>
            <div class="value"><a
                href="https://my.sakuramobile.jp/document_files/v2add/{{contract_content.contract_summary.contract_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="!isSubscriptionFiber" class="item w1">
            <div class="title">[SIM/WIFI] 親権者同意書リンク</div>
            <div class="value"><a
                href="{{parental_consent_root}}/parental-consent-form?contract_hash={{contract_content.contract_summary.contract_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="isSubscriptionESIM" class="item w1">
            <div class="title">[eSIM] アクティベーションリンク</div>
            <div class="value"><a
                href="{{esim_activation_root}}/order_auth/access_hash={{assigned_esim_access_hash}}"
                target="_blank">Link</a></div>
          </div>
          <div *ngIf="isSubscriptionVESIM" class="item w1">
            <div class="title">[eSIM] EKYC-Manager</div>
            <div class="value"><a
                href="{{liquid_ekyc_manager}}{{ekyc_uid}}"
                target="_blank">Link</a></div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            ユーザー情報
            <button mat-icon-button class="add_icon" (click)="onAddUserPropertyClicked(contract_content.user_summary)">
              <mat-icon aria-label="Add">add_circle_outline</mat-icon>
            </button>
          </div>
          <div *ngFor="let property of contract_content.user_properties">
            <div *ngIf="property.show_status === 1">
              <div
                *ngIf="property.key !== 'USR EMAIL ADDRESS'; then userNameProperties else userContractEmailAddressProperty">
              </div>
              <ng-template #userNameProperties>
                <div class="item w1">
                  <div class="title">{{property.title}}</div>
                  <div class="value" [matTooltip]="updaterForUserProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="remove_icon" (click)="onRemoveUserPropertyClicked(property)">
                      <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button class="edit_icon" (click)="onEditUserPropertyClicked(property)">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{property.value}}
                  </div>
                </div>
              </ng-template>
              <ng-template #userContractEmailAddressProperty>
                <div class="item w1">
                  <div class="title">{{property.title}}</div>
                  <!-- <button mat-icon-button class="remove_icon" (click)="onRemoveUserPropertyClicked(property)">
                                        <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                                    </button> -->
                  <div class="value" [matTooltip]="updaterForUserProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onContractEmailAddressPropertyClicked({contract: contract_content.contract_summary, property: property})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{property.value}}
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div>
            <div class="item w1">
              <div class="title">親権者同意書確認</div>
              <div *ngIf="parental_consent; then parentalConsentLink; then parentalConsentNone">
              </div>
              <ng-template #parentalConsentLink>
                <div *ngIf="parental_consent_submitted" class="value">
                  <a href="operation/parental_consent?contract_id={{contract_id}}" target="_blank">
                    Open
                  </a>
                </div>
                <div *ngIf="!parental_consent_submitted" class="value">
                  <a href="operation/parental_consent?contract_id={{contract_id}}" target="_blank">
                    Open (記載なし)
                  </a>
                </div>
              </ng-template>
              <ng-template #parentalConsentNone>
                <div class="value">
                  なし
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- 契約情報 -->
        <div class="section">
          <div class="section-title">
            契約情報
            <button mat-icon-button class="add_icon"
              (click)="onAddContractPropertyClicked(contract_content.contract_summary)">
              <mat-icon aria-label="Add">add_circle_outline</mat-icon>
            </button>
          </div>
          <div *ngFor="let property of contract_content.contract_properties">
            <!-- <div>{{property.title}} : {{property.key}} : {{property.show_status}}</div> -->
            <div *ngIf="property.show_status === 1">
              <div class="item w1">
                <div *ngIf="!(property.key === 'START DATE' || 
                                    property.key === 'END DATE' || 
                                    property.key === 'CONTRACT END DATE' || 
                                    property.key === 'LAST PAYMENT DATE' || 
                                    property.key === 'RETURN DATE' || 
                                    property.key === 'RETURN CHECKER' || 
                                    property.key === 'MNP CONTRACT DATE OF BIRTH' || 
                                    property.key === 'MNP INCOMING DATE' || 
                                    property.key === 'MNP OUTGOING DATE' || 
                                    property.key === 'MNP INCOMING EXPIRE DATE' || 
                                    property.key === 'PICKUP DATE' || 
                                    property.key === 'PREFERRED DATE OF PICKUP'||
                                    property.key === 'DOC FILES' ||
                                    property.key.includes('DOC FILE '))">
                  <div class="title">{{property.title}}</div>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                      <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button class="edit_icon" (click)="onEditContractPropertyClicked(property, null)">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{property.value}}
                  </div>
                </div>

                <!-- (特)DOC FILE * -->
                <div *ngIf="property.key.includes('DOC FILE ')">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon" (click)="onEditContractPropertyClicked(property, null)">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    <a href="{{doc_url_root}}/{{property.value}}" target="_blank">{{property.value}}</a>
                  </div>
                </div>

                <!-- (特)DOC FILES * -->
                <div *ngIf="property.key.toUpperCase() === 'DOC FILES'">
                  <div class="title">{{property.title}}</div>
                  <div class="value">
                    <div *ngFor="let document_file of document_files">
                      <span><a href="{{doc_url_root}}/{{document_file.file_name}}"
                          target="_blank">{{document_file.file_name}}</a></span>
                    </div>
                  </div>
                </div>

                <!-- (特)START DATE -->
                <div *ngIf="property.key === 'START DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)END DATE -->
                <div *ngIf="property.key === 'END DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'end'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)CONTRACT END DATE -->
                <div *ngIf="property.key === 'CONTRACT END DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'end'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)LAST PAYMENT DATE -->
                <div *ngIf="property.key === 'LAST PAYMENT DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)RETURN DATE -->
                <div *ngIf="property.key === 'RETURN DATE'">
                  <div class="title" style="height: 1.5rem; padding-top: 0.5rem;">{{property.title}}
                  </div>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)RETURN CHECKER -->
                <div *ngIf="property.key === 'RETURN CHECKER'">
                  <div class="title" style="height: 1.5rem; padding-top: 0.5rem;">{{property.title}}
                  </div>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    {{property.value}}
                  </div>
                </div>

                <!-- (特)MNP INCOMING DATE -->
                <div *ngIf="property.key === 'MNP INCOMING DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)MNP OUTGOING DATE -->
                <div *ngIf="property.key === 'MNP OUTGOING DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'end'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)MNP CONTRACT DATE OF BIRTH -->
                <div *ngIf="property.key === 'MNP CONTRACT DATE OF BIRTH'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)MNP INCOMING EXPIRE DATE -->
                <div *ngIf="property.key === 'MNP INCOMING EXPIRE DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'end'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)PICKUP DATE -->
                <div *ngIf="property.key === 'PICKUP DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>


                <!-- (特)HOTEL CHECK IN DATE -->
                <div *ngIf="property.key === 'HOTEL CHECK IN DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)HOTEL CHECK OUT DATE -->
                <div *ngIf="property.key === 'HOTEL CHECK OUT DATE'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'end'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>

                <!-- (特)PREFERRED DATE OF PICKUP -->
                <div *ngIf="property.key === 'PREFERRED DATE OF PICKUP'">
                  <div class="title">{{property.title}}</div>
                  <button mat-icon-button class="remove_icon" (click)="onRemoveContractPropertyClicked(property)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <div class="value" [matTooltip]="updaterForContractProperty(property)" matTooltipPosition="above">
                    <button mat-icon-button class="edit_icon"
                      (click)="onEditContractPropertyClicked(property, {time_adjust: 'begin'})">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    {{getDisplayDateString(property.value)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 契約商品 -->
        <div class="section">
          <div class="section-title">
            契約商品
            <button mat-icon-button class="add_icon" (click)="onAddProductClicked(contract_content.contract_summary)">
              <mat-icon aria-label="Add product">add_circle_outline</mat-icon>
            </button>
          </div>
          <div class="content-container">
          <div *ngFor="let summary of contract_content.product_summaries">
            <mat-expansion-panel [expanded]="getContractProductExpanded(summary.contract_product_id)"
              (opened)="setContractProductExpanded(summary.contract_product_id, true)"
              (closed)="setContractProductExpanded(summary.contract_product_id, false)">
              <mat-expansion-panel-header [collapsedHeight]="'5rem'" [expandedHeight]="'5rem'">
                <div *ngIf="summary.is_recent_contract_product === true; then productIsRecent else productIsNotRecent">
                </div>
                <ng-template #productIsRecent>
                  <table>
                    <tr>
                      <td>
                        <mat-panel-title class="recent_product_panel_title">
                          <div class="product_operation_area">
                            <button mat-icon-button class="remove_icon" (click)="onRemoveProductClicked(summary)">
                              <mat-icon aria-label="Remove product">remove_circle_outline</mat-icon>
                            </button>
                            <button mat-icon-button class="add_icon" (click)="onChangeProductClicked(summary)">
                              <mat-icon aria-label="Transfer new product">content_copy</mat-icon>
                            </button>
                          </div>
                          <div class="product_title">{{summary.title}}</div>
                          <button mat-icon-button class="add_icon" (click)="onAddProductPropertyClicked(summary)">
                            <mat-icon aria-label="Add">add_circle_outline</mat-icon>
                          </button>
                          <button *ngIf="isReissueESIMProduct(summary)" mat-icon-button class="icon" (click)="onReissueESIM(summary)">
                            <mat-icon aria-label="Reissue eSIM now">qr_code_2</mat-icon>
                          </button>
                          <div *ngIf="isSIMProduct(summary)" class="iij_link">
                            <a href="https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/index.cgi?TYPE=1&STATUS=0&STAGE=1&INFO={{phoneNumber(summary).telno}}" target="_blank">
                              IIJ:{{phoneNumber(summary).telno}}
                            </a>
                          </div>
                          <div *ngIf="summary.title=='Sakura Fiber Internet'">
                            <button mat-button  (click)="onChangeFiberType(summary)">→HIKARI CROSS</button>
                          </div>
                          <div *ngIf="summary.title=='Sakura Fiber Internet (HIKARI CROSS)'">
                            <button mat-button (click)="onChangeFiberType(summary)">→Regular Fiber</button>
                          </div>
                        </mat-panel-title>
                      </td>
                    </tr>
                  
                    <tr style="display: flex;">
                      <td>
                        <div *ngIf="summary.v2_status_data === null || summary.v2_status_data === undefined">
                          <button (click)="getLineStatusV2(summary)">回線状態取得</button>
                        </div>
                        <div *ngIf="summary.v2_status_data !== null &&  summary.v2_status_data !== undefined">
                          クーポン残量 {{summary.v2_status_data.valid_coupon}}MB&nbsp;:&nbsp;
                          今月クーポン使用量(高速/前日まで:{{summary.v2_status_data.coupon_stop ? '中断中' : '利用中'}}){{summary.v2_status_data.used_packets}}MB<br/>
                          回線状態 {{summary.v2_status_data.line_pause ? '中断中' : '利用中'}}&nbsp;<button (click)="postLinePauseStatus(summary)">{{summary.v2_status_data.line_pause ? '有効にする' : '中断にする'}}</button>
                          <ng-container *ngIf="summary.v2_status_data.five_g !== null">
                            &nbsp;:&nbsp;<wbr/>5G {{summary.v2_status_data.five_g ? '使用中' : '未使用'}}&nbsp;<button (click)="post5GLineStatus(summary)">{{summary.v2_status_data.five_g ? '使用しない' : '使用する'}}</button>
                          </ng-container>
                          <div class="final-line"></div>
                        </div>
                      </td>

                      <td>
                        <div style="padding-left: 8px;">
                          <button *ngIf="!isChargeListVisible(summary.contract_product_id)" (click)="getListCharge(summary.contract_product_id)"
                          >一時支払いCharge</button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                      </td>
                    </tr>
                  </table>
                </ng-template>
                <ng-template #productIsNotRecent>
                  <mat-panel-title class="not_recent_product_panel_title">
                    <div class="product_operation_area">
                    </div>
                    <div class="product_title">{{summary.title}}</div>
                    <button mat-icon-button class="add_icon" (click)="onAddProductPropertyClicked(summary)">
                      <mat-icon aria-label="Add">add_circle_outline</mat-icon>
                    </button>
                  </mat-panel-title>
                </ng-template>
              </mat-expansion-panel-header>

              <div *ngIf="isChargeListVisible(summary.contract_product_id)" class="charge-button-open">
                  <div class="item-selector">
                    <input type="text" [(ngModel)]="selectedItem" class="item-select-form" readonly 
                      [ngClass]="{'selected': isItemSelected && selectedItem !== '', 'unselected': !isItemSelected || selectedItem == ''}"/>
                    <button class="dropdown-btn" (click)="toggleDropdown()">▼</button>
                    <ul *ngIf="dropdownVisible" class="dropdown-menu">
                      <li (click)="selectItem('MNP Issue')">MNP Issue</li>
                      <li (click)="selectItem('SIM Reissue')">SIM Reissue</li>
                      <li (click)="selectItem('eSIM切り替え')">eSIM切り替え</li>
                      <li (click)="selectItem('Loss fee')">Loss fee</li>
                      <li (click)="selectItem('Damage fee')">Damage fee</li>
                      <li (click)="selectItem('TOPUP')">TOPUP</li>
                      <li (click)="selectItem('Other')">Other</li>
                    </ul>
                  </div>
                <div class="table-container">
                  <div class="row">
                    <td class="order-title">GMO Order ID</td>
                    <form [formGroup]="GmoformGroup" class="error-validator">
                      <td class="order-form"><input type="text" class="order-form-size" [formControlName]="'orderIdInput'"/></td>
                      <div *ngIf="GmoformGroup.get('orderIdInput')?.invalid && GmoformGroup.get('orderIdInput')?.touched" class="error-message-gmo">
                        27文字以下の半角英数字で入力して下さい。
                      </div>
                    </form>
                    <td class="order-title">Amount (with VAT)</td>
                    <td class="input-fee">
                    <form [formGroup]="formGroup" class="error-validator">
                      <input type="text" class="charge-form"
                      [(ngModel)]="selectedValues[selectedDropdownType]"
                      [formControlName]="'chargeInput'"
                      (ngModelChange)="calculateTax(selectedDropdownType)"
                      (focus)="showDropdown(selectedDropdownType)"
                      (blur)="blurHandler(selectedDropdownType)"
                      placeholder="0"
                      />
                      <div *ngIf="formGroup.get('chargeInput')?.invalid && formGroup.get('chargeInput')?.touched" class="error-message">
                        半角数字で入力して下さい。
                      </div>
                    </form>
                      <ul *ngIf="showDropdownMNP" class="dropdown-list">
                        <li (mousedown)="selectOption('1000', 'MNP Issue')">1000</li>
                      </ul>
                      <ul *ngIf="showDropdownSIM" class="dropdown-list">
                        <li (mousedown)="selectOption('500', 'SIM Reissue')">500</li>
                        <li (mousedown)="selectOption('3000', 'SIM Reissue')">3000</li>
                      </ul>
                      <ul *ngIf="showDropdownESIM" class="dropdown-list">
                        <li (mousedown)="selectOption('500', 'eSIM切り替え')">500</li>
                      </ul>
                      <input type="text" class="tax-form" [value]="taxValue || ''" placeholder=" Include Tax" readonly/>
                    </td>
                    <td *ngIf="this.contract_content.contract_properties[3].value === 'CREDIT CARD'" class="memo-form">
                      <textarea type="text" class="memo-form-size" [(ngModel)]="memoContent"></textarea>
                    </td>
                  </div>

                  <td class="charge-button-position">
                    <button class="cancel-button" (click)="cancelListCharge(summary.contract_product_id)">CANCEL</button>
                    <button class="charge-button" *ngIf="this.contract_content.contract_properties[3].value === 'CREDIT CARD'"
                    (click)="onClickChargeDialog(summary)"
                    >CREDIT CHARGE</button>
                    <button class="charge-button" *ngIf="this.contract_content.contract_properties[3].value === 'SMARTPIT'"
                    (click)="onClickChargeDialog(summary)"
                    >SMARTPIT CSV</button>
                  </td>
                </div>
                <div class="final-line"></div>
              </div>


              <div class="item w1 product-property">
                <div class="title">利用開始日</div>
                <div class="value" [matTooltip]="updaterForContractProductStartAt(summary)" matTooltipPosition="above">
                  <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductStartAt(summary)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <button mat-icon-button class="edit_icon" (click)="onEditContractProductStartAt(summary)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  {{getDisplayDateString(summary.start_at)}}
                </div>
              </div>
              <div class="item w1 product-property">
                <div class="title">利用終了日</div>
                <div class="value" [matTooltip]="updaterForContractProductEndAt(summary)" matTooltipPosition="above">
                  <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductEndAt(summary)">
                    <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                  </button>
                  <button mat-icon-button class="edit_icon" (click)="onEditContractProductEndAt(summary)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  {{getDisplayDateString(summary.end_at)}}
                </div>
              </div>
              <div *ngIf="summary.is_recent_contract_product === true">
                <div class="item w1 product-property">
                  <div class="title">返却状況</div>
                  <div class="value">
                    <table style="width: 100%">
                      <tr>
                        <td style="width: 1rem">
                          <button mat-icon-button class="edit_icon" (click)="onEditProductReturnInformation(summary)">
                            <mat-icon aria-label="Edit">edit</mat-icon>
                          </button>
                        </td>
                        <td style="width: 5rem; text-align: left">
                          返却確認
                        </td>
                        <td style="text-align: left">
                          {{summary.return_check_date === null ? '' :
                                                    getDisplayDateString(summary.return_check_date) + ' / ' +
                                                    getNamePart(summary.return_checker_e_mail)}}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style="text-align: left">
                          返却状況
                        </td>
                        <td style="text-align: left">
                          {{summary.return_date === null ? '' : '返却済み : ' +
                                                    getDisplayDateString(summary.return_date) + ' / ' +
                                                    getNamePart(summary.return_checker_e_mail)}}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div *ngFor="let property of contractProductProperties(summary.contract_product_id)">
                <div>
                  <div *ngIf="!(property.key === 'FIBER PLAN TYPE' ||
                                property.key === 'FIBER GOOGLE MAP INFO' ||
                                property.key === 'FIBER DESIRED INSTALLATION DATE' ||
                                property.key === 'FIBER CONTACT SCHEDULE' ||
                                property.key === 'FIBER INSTALLATION TYPE' ||
                                property.key === 'FIBER RENTAL EQUIPMENT')" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductProperty(property)">
                        <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(property)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                      {{formatContractProductPropertyString(property)}}
                      <button *ngIf="property.title === 'MNP契約者名' && formatContractProductPropertyString(property)" (click)="openOrderRequestMNPNumberDialog(summary)" style="margin-left: 1rem;">MNP転出番号発行</button>
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER PLAN TYPE'" class="item w1 product-property">
                    <div class="title">[FIBER] プランタイプ</div>
                    <div class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductProperty(property)">
                        <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(property)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                      {{property.value}}
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER INSTALLATION TYPE'" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductProperty(property)">
                        <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(property)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                      {{property.value}}
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER RENTAL EQUIPMENT'" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <button mat-icon-button class="remove_icon" (click)="onClickRemoveProductProperty(property)">
                        <mat-icon aria-label="Remove">remove_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(property)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                      {{property.value}}
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER GOOGLE MAP INFO'" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div
                      *ngIf="property.value && property.value.length > 0 && googleMapLatLng(property.value).lat && googleMapLatLng(property.value).lng"
                      class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <a href="https://www.google.com/maps?ll={{googleMapLatLng(property.value).lat}},{{googleMapLatLng(property.value).lng}}&q={{googleMapLatLng(property.value).lat}},{{googleMapLatLng(property.value).lng}}&z=21"
                        target="_blank"
                        style="font-weight: bold;">&nbsp;Map&nbsp;</a>
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER DESIRED INSTALLATION DATE'" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div class="value" [matTooltip]="updaterForProductProperty(property)" matTooltipPosition="above">
                      <div *ngIf="property.value !== null" style="white-space: nowrap;">
                        1. {{jsonParse(property.value).first.date}} {{jsonParse(property.value).first.time}}
                      </div>
                      <div *ngIf="property.value !== null" style="white-space: nowrap;">
                        2. {{jsonParse(property.value).second.date}} {{jsonParse(property.value).second.time}}
                      </div>
                      <div *ngIf="property.value !== null" style="white-space: nowrap;">
                        3. {{jsonParse(property.value).third.date}} {{jsonParse(property.value).third.time}}
                      </div>
                    </div>
                  </div>

                  <div *ngIf="property.key === 'FIBER CONTACT SCHEDULE'" class="item w1 product-property">
                    <div class="title">{{property.title}}</div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Monday : {{jsonParse(property.value).monday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Tuesday : {{jsonParse(property.value).thursday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Wednesday : {{jsonParse(property.value).wednesday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Thursday : {{jsonParse(property.value).thursday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Friday : {{jsonParse(property.value).friday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Saturday : {{jsonParse(property.value).saturday}}
                    </div>
                    <div *ngIf="property.value !== null" style="white-space: nowrap;">
                      Sunday : {{jsonParse(property.value).sunday}}
                    </div>
                  </div>

                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      </td>
      <td class="pay-history">
        <app-list-contract-pay-histories></app-list-contract-pay-histories>
      </td>
      <td class="sub-info">
        <app-list-contract-memos></app-list-contract-memos>
      </td>
    </tr>
  </table>
</div>
