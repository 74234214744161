<div class="app-operation">
    <mat-toolbar>
        <span>{{toolbar_title}}</span>
        <table id="monthly_summary" style="margin-left: auto; margin-right: 0;">
            <tr>
                <td>
                    <button mat-icon-button class="notification_icon"
                        (click)="onUpdateMonthlySummary($event)">
                        <mat-icon aria-label="Update monthly summary">cached</mat-icon>
                    </button>
                </td>
                <td *ngIf="monthly_summary !== null" style="font-size: 0.65em;
                    line-height: 1em;">
                    <table>
                        <tr>
                            <td colspan="3">
                                <table>
                                    <tr>
                                        <td colspan="9">
                                            {{monthly_summary.updated_at_str}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            ACTIVE
                                        </td>
                                        <td>
                                            All {{monthly_summary.total.active.items}}
                                        </td>
                                        <td>
                                            Vp {{monthly_summary.voice.active}}
                                        </td>
                                        <td>
                                            Ve {{monthly_summary.voice_e.active}}
                                        </td>
                                        <td>
                                            Vs {{monthly_summary.total.active.voice_suspends}}
                                        </td>
                                        <td>
                                            Dp {{monthly_summary.data.active}}
                                        </td>
                                        <td>
                                            De {{monthly_summary.data_e.active}}
                                        </td>
                                        <td>
                                            W {{monthly_summary.wifi.active}}
                                        </td>
                                        <td>
                                            F {{monthly_summary.fiber.active}}
                                        </td>
                                        <td>
                                            H {{monthly_summary.home_wifi.active}}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>START</td> 
                                        <td>ALL {{monthly_summary.total.current.start}}({{monthly_summary.total.last_year.start}})</td> 
                                        <td>Vp {{monthly_summary.voice.current.start}}({{monthly_summary.voice.last_year.start}})</td> 
                                        <td>Ve {{monthly_summary.voice_e.current.start}}({{monthly_summary.voice_e.last_year.start}})</td> 
                                        <td>Dp {{monthly_summary.data.current.start}}({{monthly_summary.data.last_year.start}})</td> 
                                        <td>De {{monthly_summary.data_e.current.start}}({{monthly_summary.data_e.last_year.start}})</td> 
                                        <td>W {{monthly_summary.wifi.current.start}}({{monthly_summary.wifi.last_year.start}})</td> 
                                        <td>F {{monthly_summary.fiber.current.start}}({{monthly_summary.fiber.last_year.start}})</td>
                                        <td>H {{monthly_summary.home_wifi.current.start}}({{monthly_summary.home_wifi.last_year.start}})</td>
                                    </tr>
                                    <tr>
                                        <td>END</td> 
                                        <td>ALL {{monthly_summary.total.current.end}}({{monthly_summary.total.last_year.end}})</td> 
                                        <td>Vp {{monthly_summary.voice.current.end}}({{monthly_summary.voice.last_year.end}})</td> 
                                        <td>Ve {{monthly_summary.voice_e.current.end}}({{monthly_summary.voice_e.last_year.end}})</td> 
                                        <td>Dp {{monthly_summary.data.current.end}}({{monthly_summary.data.last_year.end}})</td> 
                                        <td>De {{monthly_summary.data_e.current.end}}({{monthly_summary.data_e.last_year.end}})</td> 
                                        <td>W {{monthly_summary.wifi.current.end}}({{monthly_summary.wifi.last_year.end}})</td> 
                                        <td>F {{monthly_summary.fiber.current.end}}({{monthly_summary.fiber.last_year.end}})</td> 
                                        <td>H {{monthly_summary.home_wifi.current.end}}({{monthly_summary.home_wifi.last_year.end}})</td> 
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <button style="margin-left: auto; margin-right: 0;" mat-button
            [matMenuTriggerFor]="menu_lists">Lists</button>
        <mat-menu #menu_lists="matMenu">
            <button mat-menu-item (click)="onShowContracts($event)">Contracts</button>
            <button mat-menu-item (click)="onShowFiberSchedules($event)">Fiber Schedules</button>
            <button mat-menu-item (click)="onShowInvoices($event)">Invoices</button>
            <button mat-menu-item (click)="onShowSearchFast($event)">Search(Fast)</button>
            <button mat-menu-item (click)="onShowSearch($event)">Search</button>
            <button mat-menu-item (click)="onShowMNPPortOut($event)">MNP Port-Out</button>
            <button mat-menu-item (click)="onShowReferralPoints($event)">Referral Points</button>
            <button mat-menu-item (click)="onShowTopUpTickets($event)">TopUp Tickets</button>
        </mat-menu>

        <button style="margin-left: 0; margin-right: 0;" mat-button
            [matMenuTriggerFor]="menu_operations">Operation</button>
        <mat-menu #menu_operations="matMenu">
            <button mat-menu-item (click)="onClickDashboard($event)">Dashboard</button>
            <button mat-menu-item (click)="onClickShipWork($event)">Shipping</button>
            <button mat-menu-item (click)="onClickChargeWork($event)">Charge</button>
            <button mat-menu-item (click)="onClickActivateWork($event)">Activate</button>
            <button mat-menu-item (click)="onClickUtilities($event)">Utilities</button>
        </mat-menu>

        <button style="margin-left: 0; margin-right: 0;" mat-button
            [matMenuTriggerFor]="menu_manage">Manage</button>
        <mat-menu #menu_manage="matMenu">
            <button mat-menu-item (click)="onShowFileImport($event)">File import</button>
            <button mat-menu-item (click)="onShowFileExport($event)">File export</button>
        </mat-menu>

        <button style="margin-left: 0; margin-right: 0;" mat-button
            (click)="onClickLogout($event)">Logout</button>
        <div id="system_task_notification">
            <button mat-icon-button class="notification_icon"
                (click)="onClickSystemTaskNotifications()">
                <mat-icon aria-label="System task notification">notification_important</mat-icon>
            </button>
            <div id="system_task_notification_list">
                <div>
                    <table width="100%" class="panel_header">
                        <tr>
                            <td class="refresh_button">
                                <button mat-icon-button class="refresh_panel"
                                    (click)="onClickRefreshSystemTaskNotifications()">
                                    <mat-icon aria-label="refresh_panel
                                        notification">refresh</mat-icon>
                                </button>
                            </td>
                            <td>
                                {{notification_summary_string}}
                            </td>
                            <td class="close_button">
                                <button mat-icon-button class="close_panel"
                                    (click)="onClickCloseSystemTaskNotifications()">
                                    <mat-icon aria-label="close_panel
                                        notification">clear</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngFor="let system_task of display_system_tasks">
                    <div *ngIf="system_task.confirmed === false">
                        <table width="100%">
                            <tr>
                                <td class="start_at">{{system_task.start_at}}</td>
                                <td class="confirm_button">
                                    <button mat-icon-button class="confirm"
                                        (click)="onClickConfirmedSystemTask(system_task)">
                                        <mat-icon aria-label="clear
                                            notification">done</mat-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="title" colspan="2">{{system_task.title}}</td>
                            </tr>
                            <tr>
                                <td class="e_mail" colspan="2">{{system_task.e_mail}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-toolbar>
    <app-list-contracts [style.display]="display_list_contracts"></app-list-contracts>
    <app-fiber-schedules [style.display]="display_fiber_schedules"></app-fiber-schedules>
    <app-list-invoices [style.display]="display_list_invoices"></app-list-invoices>
    <app-shipwork [style.display]="display_ship_work"></app-shipwork>
    <app-file-import [style.display]="display_file_import"></app-file-import>
    <app-file-export [style.display]="display_file_export"></app-file-export>
    <app-dashboard [style.display]="display_dashboard"></app-dashboard>
    <app-search [style.display]="display_search"></app-search>
    <app-search-fast [style.display]="display_search_fast"></app-search-fast>
    <app-list-mnp-port-out [style.display]="display_mnp_port_out"></app-list-mnp-port-out>
    <app-charges [style.display]="display_charges"></app-charges>
    <app-utilities [style.display]="display_utilities"></app-utilities>
    <app-refferal-points [style.display]="display_refferal_points"></app-refferal-points>
    <app-topup-tickets [style.display]="display_top_up_tickets"></app-topup-tickets>
    <app-list-activate [style.display]="display_list_activate"></app-list-activate>
</div>
