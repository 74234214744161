import { Component, ViewChild, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import {
  MatSort,
  MatTableDataSource,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { element } from '@angular/core/src/render3/instructions';
import { NgZone } from '@angular/core';
import { SmartpitDialogComponent } from '../../dialog/smartpit-dialog/smartpit-dialog.component';

export class ListItemContractPayHistory {
  public user_id: number;
  public contract_id: number;
  public contract_code: string;
  public id: number;
  public invoice_month: string;
  public invoice_code: string;
  public invoice_note: string | null;
  public sub_total: number;
  public sub_total_tax: number;
  public sub_total_include_tax: number;
  public mail_send_status: number;
  public pay_sub_total: number;
  public pay_sub_total_tax: number;
  public pay_total_include_tax: number;
  public pay_status: number;
  public pay_date: string;
  public pay_error: string;
  public pay_method: string;
}

@Component({
  selector: 'app-list-contract-pay-histories',
  templateUrl: './list-contract-pay-histories.component.html',
  styleUrls: ['./list-contract-pay-histories.component.scss']
})
export class ListContractPayHistoriesComponent implements OnInit {

  public root_for_pdf: string;
  public contract_id: number;
  public unpaid_count: number;
  public pay_histories: Array<ListItemContractPayHistory>;
  public display_columns: Array<string>;
  public display_note_columns: Array<string>;
  public data_source: MatTableDataSource<ListItemContractPayHistory>;
  public invoice: any;
  public element: any[] = [];
  public deadline: string;
  public expandedRow: any = null;
  public has_invoice_notes: number[] = [];
  public is_invoice_note_visibles: number[] = [];

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) {
    this.display_columns = [
      // 'invoice_month',
      'invoice_code',
      'pdf',
      'mail_send_status',
      'sub_total_include_tax',
      'pay_total_include_tax',
      'pay_date',
      'pay_error',
      'pay_status',
      'pay_method',
      'change',
      'accordion'
    ];
    this.display_note_columns = [
      'note',
      'note_save',
      'note_blank'
    ];
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.root_for_pdf = environment.apiRoot;
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    this.refresh();
  }

  public refresh() {
    const param = {
      contract_id: this.contract_id
    };
    this.auth_service.apiContractPayHistory(param).subscribe(
      response => {
        // console.log(response);
        if (Utils.isValue(response) && Utils.isValue(response.data) && (response.data instanceof Array)) {
          this.unpaid_count = 0;
          response.data.forEach(element => {
            element.sub_total_include_tax = element.sub_total + element.sub_total_tax;
            if (element.pay_status === 0) {
              this.unpaid_count++;
            } else {
              element.pay_total_include_tax = element.pay_sub_total + element.pay_sub_total_tax;
            }
            element.isDisable = (element.pay_status === 'PAID' || element.pay_status === 1);
            if (typeof element.pay_status !== 'string') {
              element.pay_status = element.pay_status === 0 ? 'NOT_YET' : 'PAID';
            }
            // console.log(element);
          });
          this.data_source = new MatTableDataSource(response.data);
          this.data_source.sort = this.sort;
          this.data_source.data.forEach((data) => {
            console.log('data_source.data.invoice_note:', data.invoice_note);
            if (data.invoice_note !== null) {
              this.has_invoice_notes.push(data.id);
              console.log('has_invoice_notes:', this.has_invoice_notes);
            }
          })
          console.log('has_invoice_notes:', this.has_invoice_notes);
          this.is_invoice_note_visibles = this.has_invoice_notes;
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  public onChangeMailSendStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangeMailSendStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoiceSendStatus({ invoice_id: event.invoice.id, new_status: event.new_status }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }



  public onClickChargeDialog(element: any): void {
    let dialogData: any;
    if (element.pay_method === "CREDIT CARD") {
      dialogData = {
        title: '【チャージの確認】',
        message: `チャージします。よろしいですか？`
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '30rem',
        hasBackdrop: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.closeby === 'ok') {
          this.charge_recursive(element);
        }
      });
    } else if (element.pay_method === "SMARTPIT") {
      dialogData = {
        title: '【支払い締切日】',
      };
      const dialogRef = this.dialog.open(SmartpitDialogComponent, {
        width: '30rem',
        hasBackdrop: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.closeby === 'ok') {
          if (!result.payment_deadline) {
            alert('支払い締切日が入力されていません。');
            return;
          }
          this.auth_service.smartpitCvs({ invoice_id: element.id,  payment_deadline: result.payment_deadline }).subscribe({
            next: response => {
              console.log('Success:', response);
              alert('CSVファイルが添付されたメールが送信されました。');
            },
            error: err => {
              console.error('Error:', err);
              alert('CSVファイルが添付されたメールの送信に失敗しました。');
            }
          });
        }
      });
    }
  }


  private charge_recursive(currentInvoice: any): void {
    if (currentInvoice) {
      console.log("current_invoice : ", currentInvoice);
      console.log("current_invoice ID : ", currentInvoice.id)

      if (currentInvoice.pay_method === "CREDIT CARD") {
        console.log("Payment method is CREDIT CARD");

        this.auth_service.apiChargeUnpaidInvoice({ invoice_id: currentInvoice.id }).subscribe(
          response => {
            // currentInvoice.pay_status = response.pay_status;
            currentInvoice.pay_status = 'PAID' || currentInvoice.pay_status === 1
            console.log(currentInvoice.invoice_code + " PAY SUCCESS!");
            this.onChangePayStatus({
              invoice: currentInvoice,
              // new_status: response.pay_status
              new_status: currentInvoice.pay_status
            });
            console.log('currentInvoice.pay_status', currentInvoice.pay_status);
            this.cdr.detectChanges();
          },
          error => {
            console.log('currentInvoice.pay_status', currentInvoice.pay_status);
            console.log(currentInvoice.invoice_code + " PAY FAILED!");
            alert(`Chargeに失敗致しました。`);
          }
        );
      } 
    }
  }




  public onChangePayStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangePayStatus : ' + JSON.stringify(event));
    this.auth_service.apiSetInvoicePayStatus({ invoice_id: event.invoice.id, new_status: event.new_status }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          event.invoice.pay_status = event.new_status;
          this.ngZone.run(() => {
            event.invoice.pay_status = event.new_status;
            this.cdr.detectChanges();
            console.log('event.invoice.pay_status:', event.invoice.pay_status);
          });
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
        alert(`エラーが発生し処理に失敗しました。画面をリロードしてください。`);
      }
    );
  }



  public onChangePayMethod(event: any): void {
    console.log('ListInvoicesComponent::onChangePayMethod : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayMethod({ invoice_id: event.invoice.id, new_method: event.new_method }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
          event.invoice.pay_method = event.new_method;
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
        alert(`エラーが発生し処理に失敗しました。画面をリロードしてください。`);
      }
    );
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }

  public getDisplayDateString(offsetZero: string): string {
    return Utils.getDisplayDateString(offsetZero);
  }

  public toggleInvoiceNote(invoice_id: number) {
    if (this.is_invoice_note_visibles.includes(invoice_id)) {
      this.is_invoice_note_visibles = this.is_invoice_note_visibles.filter(data => data !== invoice_id);
      console.log('toggle:', this.is_invoice_note_visibles);
    }
    else {
      this.is_invoice_note_visibles.push(invoice_id);
      console.log('toggle:', this.is_invoice_note_visibles);
    }
  }

  public saveInvoiceNote(invoice_id: number, note: string) {
    note = note === '' || note === null ? null : note;
    const param = {
      invoice_id: invoice_id,
      invoice_note: note
    }
    console.log('saveInvoiceNote param:', param);
    this.auth_service.apiSaveInvoiceNote(param).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        if (Utils.isValue(response.message) && Utils.isValue(response.message.message)) {
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
        else {
          this.snackBar.open(
            'Invoice note was saved.',
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
        else {
          this.snackBar.open(
            'Error',
            'OK',
            cfg
          );
        }
      }
    );
  }

}
