import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import {
  AuthGetReply,
  ContractContent,
  UserProperty,
  ContractProperty,
  ContractProduct,
  ContractProductProperty,
  ParentalConsent
} from '../../../models';
import { Utils } from '../../../helpers';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { EditUserPropertyDialogComponent } from '../../dialog/edit-user-property-dialog/edit-user-property-dialog.component';
import { EditContractPropertyDialogComponent } from '../../dialog/edit-contract-property-dialog/edit-contract-property-dialog.component';
import { EditProductPropertyDialogComponent } from '../../dialog/edit-product-property-dialog/edit-product-property-dialog.component';
import { EditContractEmailDialogComponent } from '../../dialog/edit-contract-email-dialog/edit-contract-email-dialog.component';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import {
  EditProductReturnInformationDialogData,
  EditProductReturnInformationDialogComponent
} from '../../dialog/edit-product-return-information-dialog/edit-product-return-information-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  SelectDialogComponent,
  SelectDialogData,
  SelectDialogItem
} from '../../dialog/select-dialog/select-dialog.component';
import {
  DateDialogComponent,
  DateDialogData
} from '../../dialog/date-dialog/date-dialog.component';
import { environment } from 'src/environments/environment';
import { SmartpitDialogComponent } from '../../dialog/smartpit-dialog/smartpit-dialog.component';
import { OrderRequestMnpNumberDialogComponent } from '../../dialog/order-request-mnp-number-dialog/order-request-mnp-number-dialog.component';

export class ContractProductExpandState {
  constructor(
    public contract_product_id: number,
    public panel_open_state: boolean
  ) {
    this.contract_product_id = contract_product_id;
    this.panel_open_state = panel_open_state;
  }
}

export class DocumentFile {
  public file_name: string;
  public saved_at: string;
}

export class LocationLatLng {
  public lat: string;
  public lng: string;
}

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, AfterViewChecked {
  public doc_url_root = environment.apiRoot;
  public order_form_root = environment.orderFormRoot;
  public parental_consent_root = environment.parentalConsentRoot;
  public fiber_order_root = environment.fiberOrderRoot;
  public esim_activation_root = environment.esimActivationRoot;
  public liquid_ekyc_manager = environment.liquidEkycManager;
  public ekyc_uid = "";

  public contract_id: number;
  public contract_content: ContractContent;

  public parental_consent_submitted: boolean;
  public parental_consent: ParentalConsent;
  public visibleChargeList: { [key: string]: boolean } = {};
  public disableExpansionMap: { [id: string]: boolean } = {};
  public selectedValueMNP: number;
  public selectedValueSIM: number;
  public selectedValueESIM: number;
  public selectedValueLoss: number;
  public selectedValueDamage: number;
  public selectedValueTOPUP: number;
  public selectedValueOther: number;
  public showDropdownMNP = false;
  public showDropdownSIM = false;
  public showDropdownESIM = false;
  public isSIMReissueVisible = false;
  public taxValue: number = 0;
  public dropdownVisible = false;
  public selectedItem: string  = null;
  public isMNPFormVisible = false;
  public selectedDropdownType: string = '';
  public currentOptions: string[] = [];
  public  selectedValues: { [key: string]: number } = {};
  public isItemSelected: boolean;
  public errorMessage: string = '';
  public formGroup: FormGroup;
  public GmoformGroup: FormGroup;
  public contract_product_id: number;
  public panel_open_state: boolean;
  public summary: any;
  public data_source: any;
  public property: any;
  public contract_properties: any;
  public memoContent: string = '';
  public GmoOrderId: string;
  public to_be_contract_note = false;

  protected _el: HTMLElement;

  public confirm_dialog_ref: any;
  public select_dialog_ref: any;
  public edit_product_return_information_dialog_ref: any;
  public document_files: Array<DocumentFile>;

  public contract_product_expand_states = Array<ContractProductExpandState>();

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this._el = el.nativeElement;
    this.contract_content = new ContractContent();
    this.contract_product_expand_states = new Array<
      ContractProductExpandState
    >();
    this.parental_consent = new ParentalConsent;
    this.parental_consent_submitted = false;

    this.GmoformGroup = this.fb.group({
      orderIdInput: [
        '', 
        [
          Validators.required, 
          Validators.pattern('^[a-zA-Z0-9-]+$'),
          Validators.maxLength(27)
        ]]
    })
    this.formGroup = this.fb.group({
      chargeInput: ['', [Validators.pattern('^[0-9]*$')]]
    });
  }

  ngOnInit() {
    if (this.auth_service.isLoggingIn() !== true) {
      this.auth_service.login_after_url = window.location.href;
      location.href = '/auth/login';
      return;
    }
    this.auth_service.apiIsMemberUser('operator', this, function (
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        this.auth_service.login_after_url = window.location.href;
        location.href = '/auth/login';
      }
    });
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    if (Utils.isValue(this.contract_id)) {
      this.refresh();
    }
    const checkMemo = Utils.getUrlParameter('check_memo') === 'true';
    if (checkMemo) {
      this.snackBar.dismiss();
      const cfg = new MatSnackBarConfig();
      cfg.duration = 10000;
      cfg.panelClass = ['notify_snackbar', 'warning'];
      this.snackBar.open('Please check memo.', 'OK', cfg);
    }
  }

  ngAfterViewChecked() {
    document.title = this.contract_content.contract_summary.contract_code;
  }

  public sortDocuments(a: DocumentFile, b: DocumentFile): number {
    if (a.file_name === b.file_name) {
      return 0;
    }
    else if (a.file_name < b.file_name) {
      return 1;
    }
    return -1;
  }

  public refresh() {
    const params = {
      contract_id: this.contract_id
    };
    this.auth_service.apiGetContract(params).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          this.contract_content = <ContractContent>response.data;
          const doc_file_independents = this.contract_content.contract_properties.filter(e => {
            return e.key.includes('DOC FILE ') && e.value;
          });
          console.log(doc_file_independents);
          doc_file_independents.forEach(a_doc_file => {
            this.auth_service.apiPrepareDocument({
              file_name: a_doc_file.value
            }).subscribe(
              response => {
              },
              error => {
              }
            )
          });
          const doc_files_field = this.contract_content.contract_properties.filter(e => {
            return e.key.toUpperCase() === 'DOC FILES';
          });
          this.document_files = JSON.parse(doc_files_field[0].value);
          if (this.document_files !== null && this.document_files.length > 0) {
            this.document_files.sort(this.sortDocuments);
            this.document_files.forEach(a_doc_file => {
              this.auth_service.apiPrepareDocument({
                file_name: a_doc_file.file_name
              }).subscribe(
                response => {
                },
                error => {
                }
              )
            });
          }
          const ekyc_uid_fields = this.contract_content.user_properties.filter(e => {
            console.log(`expect EKYC UID : `, e.key);
            return e.key.toUpperCase() === 'EKYC UID'
          })
          console.log("HI!!");
          if (ekyc_uid_fields.length > 0) {
            console.log('EKYC MANGER UID', ekyc_uid_fields);
            this.ekyc_uid = ekyc_uid_fields[0].value;
          }

          this.contract_content.product_summaries.forEach(element => {
            this.contract_product_expand_states.push(
              new ContractProductExpandState(
                element.contract_product_id,
                this.getContractProductExpanded(element.contract_product_id)
              )
            );
            // if (element.title.toUpperCase().includes('SIM') &&
            //   (element.title.toUpperCase().includes('VOICE & DATA') ||
            //     element.title.toUpperCase().includes('DATA ONLY'))) {
            //   this.auth_service.apiGetLineStatus({ contract_product_id: element.contract_product_id }).subscribe(
            //     response => {
            //       console.log('success', response);
            //       element.coupon_status = response.data.line_status;
            //       element.coupon_remain = response.data.coupon_remain;
            //       element.bytes_in_month = response.data.bytes_in_month;
            //     },
            //     error => {
            //       console.log('error', response);
            //       element.coupon_status = '---';
            //     }
            //   );
            // }
          });
        }
      },
      error => {
        console.log(error);
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
    this.auth_service.apiGetParentalConsent(params).subscribe(
      response => {
        this.parental_consent = response.data ? response.data.parental_consent : null;
        this.parental_consent_submitted = this.parental_consent && this.parental_consent.email && this.parental_consent.email.length > 0;
        console.log('success to get parental consent', this.parental_consent_submitted, this.parental_consent);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
        else {
          this.snackBar.open("Checking parental consent : Server error", 'OK', cfg);
        }
      }
    );
  }

  public userProperty(key): UserProperty {
    const found = this.contract_content.user_properties.filter(function (
      property: UserProperty
    ) {
      return property.key === key;
    });
    if (found === undefined) {
      return undefined;
    }
    if (found.length <= 0) {
      return undefined;
    }
    if (found[0].value == null) {
      found[0].value = undefined;
    }
    return found[0];
  }

  public contractProperty(key): ContractProperty {
    const found = this.contract_content.contract_properties.filter(function (
      property: ContractProperty
    ) {
      return property.key === key;
    });
    if (found === undefined) {
      return undefined;
    }
    if (found.length <= 0) {
      return undefined;
    }
    if (found[0].value == null) {
      found[0].value = undefined;
    }
    return found[0];
  }

  public googleMapLatLng(location: string): LocationLatLng {
    // console.log('googleMapLatLng', location);
    return JSON.parse(location);
  }

  public get isSubscriptionFiber(): boolean {
    // console.log('isSubscriptionFiber : ', this.contract_content.contract_properties);
    if (!this.contract_content || !this.contract_content.contract_properties) {
      return false;
    }
    const properties = this.contract_content.contract_properties.filter(property => {
      return property.key === 'SUBSCRIPTION PLAN';
    });
    if (properties.length === 0 || !properties[0].value) {
      return false;
    }
    if (properties[0].value.toUpperCase() === 'SAKURA FIBER INTERNET') {
      return true;
    }
    return false;
  }

  public get isSubscriptionESIM(): boolean {
    // console.log('isSubscriptionESIM : ', this.contract_content.contract_properties);
    if (!this.contract_content || !this.contract_content.contract_properties) {
      return false;
    }
    const properties = this.contract_content.contract_properties.filter(property => {
      return property.key === 'SUBSCRIPTION TYPE';
    });
    if (properties.length === 0 || !properties[0].value) {
      return false;
    }
    if (properties[0].value.includes('_esim')) {
      return true;
    }
    return false;
  }

  public get isSubscriptionVESIM(): boolean {
    // console.log('isSubscriptionVESIM : ', this.contract_content.contract_properties);
    if (!this.contract_content || !this.contract_content.contract_properties) {
      return false;
    }
    const properties = this.contract_content.contract_properties.filter(property => {
      return property.key === 'SUBSCRIPTION TYPE';
    });
    if (properties.length === 0 || !properties[0].value) {
      return false;
    }
    if (properties[0].value.includes('voice_esim')) {
      return true;
    }
    return false;
  }

  public formatContractProductPropertyString(property: ContractProductProperty): string {
    // console.log('formatContractProductPropertyString', property);
    if (property.value_type === 'bool') {
      if (property.value === null || property.value === undefined) {
        return 'Not checked';
      }
      if (property.value === 'true' || property.value === 't' || property.value.toUpperCase() === 'CHECKED') {
        return 'Checked';
      }
      else {
        return 'Not checked';
      }
    }
    return property.value;
  }

  public jsonParse(property: string): any {
    if (property === null || property === undefined) {
      return null;
    }
    const result = JSON.parse(property);
    return JSON.parse(property);
  }

  public get fiberProductId(): number {
    console.log('fiberProductId : start');
    if (!this.contract_content || !this.contract_content.product_summaries) {
      console.log('fiberProductId : exit 0');
      return null;
    }

    const found = this.contract_content.product_summaries.find(el => {
      console.log('fiberProductId : candidates', el);
      return el.description === "Sakura Fiber Internet" || el.description === "Sakura Fiber Internet (HIKARI CROSS)";
    });
    if (!found) {
      console.log('fiberProductId : exit 1');
      return null;
    }
    console.log('fiberProductId', found);
    return found.contract_product_id;
  }

  public contractProductProperties(
    contract_product_id
  ): Array<ContractProductProperty> {
    const founds = this.contract_content.product_properties.filter(function (
      property: ContractProductProperty
    ) {
      return property.contract_product_id === contract_product_id;
    });
    return founds;
  }

  public contractProductProperty(
    contract_product_id,
    key
  ): ContractProductProperty {
    const founds = this.contract_content.product_properties.filter(function (
      property: ContractProductProperty
    ) {
      return (
        property.contract_product_id === contract_product_id &&
        property.key === key
      );
    });
    if (founds === undefined) {
      return undefined;
    }
    if (founds.length <= 0) {
      return undefined;
    }
    if (founds[0].value === null) {
      founds[0].value = undefined;
    }
    return founds[0];
  }

  public onAddUserPropertyClicked(event): void {
    const params = {
      user_id: event.id
    };
    this.auth_service.apiListUnassignedUserProperties(params).subscribe(
      response_list => {
        const dialogData = new SelectDialogData();
        dialogData.title = 'ユーザー情報の追加';
        dialogData.message = '追加する項目を選択してください';
        dialogData.placeholder = undefined;
        dialogData.current_item_key = undefined;
        dialogData.items = new Array<SelectDialogItem>();
        response_list.data.forEach(element => {
          const item = new SelectDialogItem();
          item.key = element.id;
          item.title = element.title;
          dialogData.items.push(item);
        });
        this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
          width: '30rem',
          hasBackdrop: true,
          data: dialogData
        });
        this.select_dialog_ref.afterClosed().subscribe(result => {
          if (Utils.isValue(result)) {
            if (result.closeby === 'ok') {
              const param = {
                user_property_id: parseInt(result.selected_item.key, 10)
              };
              this.auth_service.apiAddUserProperty(param).subscribe(
                response_add => {
                  console.log(response_add);
                  this.snackBar.dismiss();
                  const cfg = new MatSnackBarConfig();
                  cfg.duration = 2000;
                  cfg.panelClass = ['notify_snackbar', 'success'];
                  this.snackBar.open('User property added.', 'OK', cfg);
                  this.select_dialog_ref.close();
                  this.refresh();
                },
                error => {
                  const error_message = AuthService.getErrorMessage(error);
                  if (error_message !== undefined) {
                    this.snackBar.dismiss();
                    const cfg = new MatSnackBarConfig();
                    cfg.duration = 5000;
                    cfg.panelClass = ['notify_snackbar', 'error'];
                    this.snackBar.open(error.error.message.message, 'OK', cfg);
                  }
                }
              );
            } else if (result.closeby === 'cancel') {
            } else {
            }
          }
        });
      },
      error => {
        console.log('ContractComponent::onAddUserPropertyClicked - error');
        const error_message = AuthService.getErrorMessage(error);
        if (error_message !== undefined) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }

  public onEditUserPropertyClicked(event): void {
    console.log('ContractComponent::onEditUserPropertyClicked');
    const dialogAddRef = this.dialog.open(EditUserPropertyDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: event
    });

    dialogAddRef.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public onContractEmailAddressPropertyClicked(event): void {
    console.log('ContractComponent::onContractEmailAddressPropertyClicked');
    console.log(event);
    const dialog_data = event.property;
    dialog_data.data = event.contract;
    const dialogAddRef = this.dialog.open(EditContractEmailDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: dialog_data
    });

    dialogAddRef.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public updaterForUserProperty(property: any): string {
    if (Utils.isValue(property) && Utils.isValue(property.updated_by)) {
      const updater = property.updated_by.email;
      const updated_at = Utils.getLocalTimeString(property.updated_at);
      const return_string = updater + ' ' + updated_at;
      return return_string;
    }
    return '';
  }

  public onRemoveUserPropertyClicked(event): void {
    console.log('ContractComponent::onRemoveUserPropertyClicked');

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'ユーザープロパティの削除',
        message:
          event.title + 'のユーザープロパティを削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            user_property_id: event.user_property_id
          };
          this.auth_service.apiDeleteUserProperty(param).subscribe(
            response => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Contract property removed.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public contractStatusTitleFromStatus(status: number): string {
    let title = '不明なステータス';
    switch (status) {
      case 0:
        title = '無効';
        break;
      case 100:
        title = 'アクセスのみ';
        break;
      case 200:
        title = '入力督促済み';
        break;
      case 300:
        title = '支払い完了';
        break;
      case 400:
        title = '書類督促中';
        break;
      case 410:
        title = '(V2) STEP2以降督促済み';
        break;
      case 420:
        title = '(V2) 書類督促済み';
        break;
      case 430:
        title = '(V2) EKYC依頼中';
        break;
      case 500:
        title = '申し込み完了';
        break;
      case 600:
        title = '申し込み確認済み';
        break;
      case 650:
        title = 'Preparing for shipment';
        break;
      case 700:
        title = '出荷済み';
        break;
      case 1001:
        title = 'FIBER:質問申請あり';
        break;
      case 1101:
        title = 'FIBER:申込申請あり';
        break;
      case 1201:
        title = '固定課金開始';
        break;
      case 91900:
        title = '固定キャンセル';
        break;
      case 91901:
        title = '固定無効';
        break;
      case 99999:
        title = '申し込み確認済み';
        break;
      case 90800:
        title = '申込キャンセル';
        break;
      case 90900:
        title = '破棄';
        break;
      default:
        break;
    }
    return title;
  }

  private selectDialogItemForContractStatus(key: string, title: string): SelectDialogItem {
    const item = new SelectDialogItem();
    item.key = key;
    item.title = title;
    return item;
  }

  public onEditContractStatus(event): void {
    console.log('onEditContractStatus : ', this.contract_content);
    const dialogData = new SelectDialogData();
    dialogData.title = '契約ステータスの変更';
    dialogData.message = '選択してください';
    dialogData.placeholder = undefined;
    dialogData.current_item_key = undefined;
    dialogData.items = new Array<SelectDialogItem>();
    const item_invalid = new SelectDialogItem();
    dialogData.items.push(this.selectDialogItemForContractStatus('0', '無効'));
    dialogData.items.push(this.selectDialogItemForContractStatus('100', 'アクセスのみ'));
    dialogData.items.push(this.selectDialogItemForContractStatus('200', '入力督促済み'));
    dialogData.items.push(this.selectDialogItemForContractStatus('300', '支払い完了'));
    dialogData.items.push(this.selectDialogItemForContractStatus('400', '書類督促中'));
    dialogData.items.push(this.selectDialogItemForContractStatus('410', '(V2) STEP2以降督促済み'));
    dialogData.items.push(this.selectDialogItemForContractStatus('420', '(V2) 書類督促済み'));
    dialogData.items.push(this.selectDialogItemForContractStatus('430', '(V2) EKYC依頼中'));
    dialogData.items.push(this.selectDialogItemForContractStatus('500', '申し込み完了'));
    dialogData.items.push(this.selectDialogItemForContractStatus('650', 'Preparing for shipment'));
    dialogData.items.push(this.selectDialogItemForContractStatus('600', '申し込み確認済み'));
    dialogData.items.push(this.selectDialogItemForContractStatus('700', '出荷済み'));

    dialogData.items.push(this.selectDialogItemForContractStatus('99999', '契約終了'));
    dialogData.items.push(this.selectDialogItemForContractStatus('90800', '申込キャンセル'));
    dialogData.items.push(this.selectDialogItemForContractStatus('90900', '破棄'));

    dialogData.items.push(this.selectDialogItemForContractStatus('1001', 'FIBER:質問申請あり'));
    dialogData.items.push(this.selectDialogItemForContractStatus('1101', 'FIBER:申込申請あり'));
    dialogData.items.push(this.selectDialogItemForContractStatus('1201', '固定課金開始'));
    dialogData.items.push(this.selectDialogItemForContractStatus('91900', '固定キャンセル'));
    dialogData.items.push(this.selectDialogItemForContractStatus('91901', '固定無効'));
    this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: dialogData
    });
    this.select_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            contract_id: event.id,
            field: 'status',
            value: parseInt(result.selected_item.key, 10)
          };
          this.auth_service.apiUpdateFieldContract(param).subscribe(
            response => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Success to set contract enable.', 'OK', cfg);
              this.select_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public updaterForContractProperty(property: any): string {
    if (Utils.isValue(property) && Utils.isValue(property.updated_by)) {
      const updater = property.updated_by.email;
      const updated_at = Utils.getLocalTimeString(property.updated_at);
      const return_string = updater + ' ' + updated_at;
      return return_string;
    }
    return '';
  }

  public onAddContractPropertyClicked(event): void {
    const params = {
      contract_id: this.contract_id
    };
    this.auth_service.apiGetUnassignedContractProperties(params).subscribe(
      response_list => {
        const dialogData = new SelectDialogData();
        dialogData.title = '契約情報の追加';
        dialogData.message = '追加する項目を選択してください';
        dialogData.placeholder = undefined;
        dialogData.current_item_key = undefined;
        dialogData.items = new Array<SelectDialogItem>();
        response_list.data.forEach(element => {
          const item = new SelectDialogItem();
          item.key = element.id;
          item.title = element.title;
          dialogData.items.push(item);
        });
        this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
          width: '30rem',
          hasBackdrop: true,
          data: dialogData
        });
        this.select_dialog_ref.afterClosed().subscribe(result => {
          if (Utils.isValue(result)) {
            if (result.closeby === 'ok') {
              const param = {
                contract_property_id: parseInt(result.selected_item.key, 10)
              };
              this.auth_service.apiAddContractProperty(param).subscribe(
                response_add => {
                  this.snackBar.dismiss();
                  const cfg = new MatSnackBarConfig();
                  cfg.duration = 2000;
                  cfg.panelClass = ['notify_snackbar', 'success'];
                  this.snackBar.open('Contract property added.', 'OK', cfg);
                  this.select_dialog_ref.close();
                  this.refresh();
                },
                error => {
                  const error_message = AuthService.getErrorMessage(error);
                  if (error_message !== undefined) {
                    this.snackBar.dismiss();
                    const cfg = new MatSnackBarConfig();
                    cfg.duration = 5000;
                    cfg.panelClass = ['notify_snackbar', 'error'];
                    this.snackBar.open(error.error.message.message, 'OK', cfg);
                  }
                }
              );
            } else if (result.closeby === 'cancel') {
            } else {
            }
          }
        });
      },
      error => {
        console.log('ContractComponent::onAddContractPropertyClicked - error');
        const error_message = AuthService.getErrorMessage(error);
        if (error_message !== undefined) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }

  public onEditContractPropertyClicked(event, option): void {
    event['option'] = option;
    const dialogAddRef = this.dialog.open(EditContractPropertyDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: event,
    });

    dialogAddRef.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public onRemoveContractPropertyClicked(event): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '契約プロパティの削除',
        message: event.title + 'の契約プロパティを削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          console.log(
            'ContractComponent::onRemoveContractPropertyClicked() - closed by ok'
          );
          const param = {
            contract_property_id: event.contract_property_id
          };
          this.auth_service.apiRemoveContractProperty(param).subscribe(
            response => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Contract property removed.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onEditContractProductStartAt(event): void {
    const dialogData = new DateDialogData();
    dialogData.title = '利用開始日';
    dialogData.message = '選択してください';
    if (
      Utils.isValue(event.start_at) &&
      event.start_at.split('T')[0].length > 0
    ) {
      dialogData.initial_date = this.getDisplayDateString(event.start_at);
    } else {
      dialogData.initial_date = undefined;
    }
    this.select_dialog_ref = this.dialog.open(DateDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: dialogData
    });
    this.select_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          console.log(result.selected_date);
          const param = {
            contract_product_id: event.contract_product_id,
            start_at: Utils.getBackendDatetimeString(result.selected_date, 'begin'),
          };
          this.auth_service.apiSetContractProductStartAt(param).subscribe(
            response_add => {
              console.log(response_add);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Success to set contract start date.',
                'OK',
                cfg
              );
              this.select_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public updaterForContractProductStartAt(summary: any): string {
    if (Utils.isValue(summary) && Utils.isValue(summary.start_at_updated_by)) {
      const updater = summary.start_at_updated_by;
      const updated_at = Utils.getLocalTimeString(summary.start_at_updated_at);
      const return_string = updater + ' ' + updated_at;
      return return_string;
    }
    return '';
  }

  public onClickRemoveProductStartAt(event): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '契約商品プロパティの削除',
        message: '利用開始日を削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            contract_product_id: event.contract_product_id,
            end_at: null
          };
          this.auth_service.apiSetContractProductStartAt(param).subscribe(
            response_add => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Success to set contract end date.',
                'OK',
                cfg
              );
              this.select_dialog_ref.close();
              event.start_at = null;
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onClickRemoveProductEndAt(event): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '契約商品プロパティの削除',
        message: '利用終了日を削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            contract_product_id: event.contract_product_id,
            end_at: null
          };
          this.auth_service.apiSetContractProductEndAt(param).subscribe(
            response_add => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Success to set contract end date.',
                'OK',
                cfg
              );
              this.select_dialog_ref.close();
              event.end_at = null;
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onEditContractProductEndAt(event): void {
    const dialogData = new DateDialogData();
    dialogData.title = '利用終了日';
    dialogData.message = '選択してください';
    if (Utils.isValue(event.end_at) && event.end_at.split('T')[0].length > 0) {
      dialogData.initial_date = this.getDisplayDateString(event.end_at);
    } else {
      dialogData.initial_date = undefined;
    }
    this.select_dialog_ref = this.dialog.open(DateDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: dialogData
    });
    this.select_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const selected_date = new Date(result.selected_date + ' 23:59:59.999');
          const param = {
            contract_product_id: event.contract_product_id,
            end_at: selected_date.toISOString()
          };
          this.auth_service.apiSetContractProductEndAt(param).subscribe(
            response_add => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Success to set contract end date.',
                'OK',
                cfg
              );
              this.select_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onEditProductReturnInformation(summary: any): void {
    const dialogData = new EditProductReturnInformationDialogData();
    dialogData.return_info = summary;
    this.edit_product_return_information_dialog_ref = this.dialog.open(EditProductReturnInformationDialogComponent, {
      width: '40rem',
      hasBackdrop: true,
      data: dialogData
    });
    this.edit_product_return_information_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const params = {
            contract_id: summary.contract_id,
            contract_product_id: summary.contract_product_id,
            return_date: Utils.isValue(result.return_date) ? Utils.getBackendDatetimeString(result.return_date, 'begin') : null,
            return_check_date: Utils.isValue(result.return_check_date) ? Utils.getBackendDatetimeString(result.return_check_date, 'begin') : null,
          };
          this.auth_service.apiSetContractProductReturnInformation(params).subscribe(
            response => {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Success to set product returning information.',
                'OK',
                cfg
              );
              this.edit_product_return_information_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error_message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public updaterForContractProductEndAt(summary: any): string {
    if (Utils.isValue(summary) && Utils.isValue(summary.end_at_updated_by)) {
      const updater = summary.end_at_updated_by;
      const updated_at = Utils.getLocalTimeString(summary.end_at_updated_at);
      const return_string = updater + ' ' + updated_at;
      return return_string;
    }
    return '';
  }

  public onChangeProductClicked(event): void {
    this.auth_service.apiGetProductList().subscribe(
      response_list => {
        const dialogData = new SelectDialogData();
        dialogData.title = '商品の変更';
        dialogData.message = '変更先の商品を選択してください';
        dialogData.placeholder = undefined;
        dialogData.current_item_key = undefined;
        dialogData.items = new Array<SelectDialogItem>();
        response_list.data.forEach(element => {
          const item = new SelectDialogItem();
          item.key = element.id;
          item.title = element.title;
          dialogData.items.push(item);
        });
        this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
          width: '30rem',
          hasBackdrop: true,
          data: dialogData
        });
        this.select_dialog_ref.afterClosed().subscribe(result => {
          if (Utils.isValue(result)) {
            if (result.closeby === 'ok') {
              const param = {
                contract_id: this.contract_id,
                new_product_type_id: parseInt(result.selected_item.key, 10),
                old_product_id: event.contract_product_id
              };
              this.auth_service.apiAddContractProduct(param).subscribe(
                response_add => {
                  this.snackBar.dismiss();
                  const cfg = new MatSnackBarConfig();
                  cfg.duration = 2000;
                  cfg.panelClass = ['notify_snackbar', 'success'];
                  this.snackBar.open(
                    '(Active) Contract product changed.',
                    'OK',
                    cfg
                  );
                  this.select_dialog_ref.close();
                  this.refresh();
                },
                error => {
                  const error_message = AuthService.getErrorMessage(error);
                  if (error_message !== undefined) {
                    this.snackBar.dismiss();
                    const cfg = new MatSnackBarConfig();
                    cfg.duration = 5000;
                    cfg.panelClass = ['notify_snackbar', 'error'];
                    this.snackBar.open(error.error.message.message, 'OK', cfg);
                  }
                }
              );
            } else if (result.closeby === 'cancel') {
            } else {
            }
          }
        });
      },
      error => {
        console.log('ContractComponent::onChangeProductClicked - error');
        const error_message = AuthService.getErrorMessage(error);
        if (error_message !== undefined) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }

  public onAddProductClicked(event): void {
    this.auth_service.apiGetProductList().subscribe(
      response_list => {
        const dialogData = new SelectDialogData();
        dialogData.title = '商品の追加';
        dialogData.message = '追加する商品を選択してください';
        dialogData.placeholder = undefined;
        dialogData.current_item_key = undefined;
        dialogData.items = new Array<SelectDialogItem>();
        response_list.data.forEach(element => {
          const item = new SelectDialogItem();
          item.key = element.id;
          item.title = element.title;
          dialogData.items.push(item);
        });
        this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
          width: '30rem',
          hasBackdrop: true,
          data: dialogData
        });
        this.select_dialog_ref.afterClosed().subscribe(result => {
          if (Utils.isValue(result)) {
            if (result.closeby === 'ok') {
              const param = {
                contract_id: this.contract_id,
                new_product_type_id: parseInt(result.selected_item.key, 10),
              };
              this.auth_service.apiAddContractProduct(param).subscribe(
                response_add => {
                  console.log(response_add);
                  this.snackBar.dismiss();
                  const cfg = new MatSnackBarConfig();
                  cfg.duration = 2000;
                  cfg.panelClass = ['notify_snackbar', 'success'];
                  this.snackBar.open('Product added.', 'OK', cfg);
                  this.select_dialog_ref.close();
                  this.refresh();
                },
                error => {
                  console.log('ContractComponent::onAddProductClicked - error');
                  const error_message = AuthService.getErrorMessage(error);
                  if (error_message !== undefined) {
                    this.snackBar.dismiss();
                    const cfg = new MatSnackBarConfig();
                    cfg.duration = 5000;
                    cfg.panelClass = ['notify_snackbar', 'error'];
                    this.snackBar.open(error.error.message.message, 'OK', cfg);
                  }
                }
              );
            } else if (result.closeby === 'cancel') {
            } else {
            }
          }
        });
      },
      error => {
        const error_message = AuthService.getErrorMessage(error);
        if (error_message !== undefined) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }

  public onRemoveProductClicked(event): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '契約商品の削除',
        message: event.title + 'の商品を契約から削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          // TODO : Remove Product
          const param = {
            contract_product_id: event.contract_product_id
          };
          this.auth_service.apiRemoveContractProduct(param).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Product removed.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onChangeFiberType(data): void {
    console.log("onChangeFiberType", data);
    let to_title = 'Sakura Fiber Internet (HIKARI CROSS)';
    let to_hikari_cross = true;
    if (data.title === 'Sakura Fiber Internet (HIKARI CROSS)') {
      to_title = 'Sakura Fiber Internet';
      to_hikari_cross = false;
    }
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'ファイバー商品の変更',
        message: `"${data.title}" を "${to_title}" に変更します。よろしいですか？'`
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            contract_product_id: data.contract_product_id,
            to_hikari_cross: to_hikari_cross
          };
          this.auth_service.apiChangeFiberType(param).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Fiber Item type changed.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onAddProductPropertyClicked(event): void {
    const params = {
      contract_id: event.contract_id,
      contract_product_id: event.contract_product_id
    };
    this.auth_service
      .apiGetUnassignedContractProductProperties(params)
      .subscribe(
        response_list => {
          const dialogData = new SelectDialogData();
          dialogData.title = '契約商品情報の追加';
          dialogData.message = '追加する項目を選択してください';
          dialogData.placeholder = undefined;
          dialogData.current_item_key = undefined;
          dialogData.items = new Array<SelectDialogItem>();
          response_list.data.forEach(element => {
            const item = new SelectDialogItem();
            item.key = element.contract_product_property_id;
            item.title = element.title;
            dialogData.items.push(item);
          });
          this.select_dialog_ref = this.dialog.open(SelectDialogComponent, {
            width: '30rem',
            hasBackdrop: true,
            data: dialogData
          });
          this.select_dialog_ref.afterClosed().subscribe(result => {
            if (Utils.isValue(result)) {
              if (result.closeby === 'ok') {
                const param = {
                  contract_product_property_id: parseInt(
                    result.selected_item.key,
                    10
                  )
                };
                this.auth_service
                  .apiAddContractProductProperty(param)
                  .subscribe(
                    response_add => {
                      this.snackBar.dismiss();
                      const cfg = new MatSnackBarConfig();
                      cfg.duration = 2000;
                      cfg.panelClass = ['notify_snackbar', 'success'];
                      this.snackBar.open('Product property added.', 'OK', cfg);
                      this.select_dialog_ref.close();
                      this.refresh();
                    },
                    error => {
                      const error_message = AuthService.getErrorMessage(error);
                      if (error_message !== undefined) {
                        this.snackBar.dismiss();
                        const cfg = new MatSnackBarConfig();
                        cfg.duration = 5000;
                        cfg.panelClass = ['notify_snackbar', 'error'];
                        this.snackBar.open(
                          error.error.message.message,
                          'OK',
                          cfg
                        );
                      }
                    }
                  );
              } else if (result.closeby === 'cancel') {
              } else {
              }
            }
          });
        },
        error => {
          const error_message = AuthService.getErrorMessage(error);
          if (error_message !== undefined) {
            this.snackBar.dismiss();
            const cfg = new MatSnackBarConfig();
            cfg.duration = 5000;
            cfg.panelClass = ['notify_snackbar', 'error'];
            this.snackBar.open(error.error.message.message, 'OK', cfg);
          }
        }
      );
  }

  public updaterForProductProperty(property: any): string {
    if (Utils.isValue(property) && Utils.isValue(property.updated_by)) {
      const updater = property.updated_by.email;
      const updated_at = Utils.getLocalTimeString(property.updated_at);
      const return_string = updater + ' ' + updated_at;
      return return_string;
    }
    return '';
  }

  public onClickEditProductProperty(event): void {
    const dialogAddRef = this.dialog.open(EditProductPropertyDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: event
    });

    dialogAddRef.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public onClickRemoveProductProperty(event): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '契約商品プロパティの削除',
        message: event.title + 'の商品プロパティを削除します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const param = {
            id: event.contract_product_property_id
          };
          this.auth_service.apiSetContractProductProperty(param).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Product property removed.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              console.log(error.error.message.message);
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }
  public getLineStatusV2(summary): void {
    const params = {
      contract_product_id: 80733
    };
    this.auth_service.apiGetLineStatusV2({ contract_product_id: summary.contract_product_id }).subscribe(
      response => {
        summary.v2_status_data = response.data;
        console.log(response);
      },
      error => {
        console.log(error.error.message.message);
      }
    );
  }

  public postLinePauseStatus(summary): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '回線状態設定',
        message: `${summary.v2_status_data.line_pause ? '中断中' : '利用中'} を ${summary.v2_status_data.line_pause ? '利用中' : '中断中'} に変更しますか？`
      }
    });
    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiPostLinePauseStatus({
            contract_product_id: summary.contract_product_id,
            status: summary.v2_status_data.line_pause ? 'resume' : 'pause'
          }).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 3000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('回線状態設定を変更しました', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.getLineStatusV2(summary);
            },
            error => {
              console.log(error.error.message.message);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open('回線状態設定の変更に失敗しました', 'OK', cfg);
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
    this.auth_service.apiPostLinePauseStatus({ contract_product_id: summary.contract_product_id, status: 'resume' }).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error.error.message.message);
      }
    );
  }

  public postLineCouponStatus(summary): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'クーポン利用設定',
        message: `${summary.v2_status_data.coupon_stop ? '中断中' : '利用中'} を ${summary.v2_status_data.coupon_stop ? '利用中' : '中断中'} に変更しますか？`
      }
    });
    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiPostLineCouponStatus({
            contract_product_id: summary.contract_product_id,
            status: summary.v2_status_data.coupon_stop ? 'resume' : 'pause'
          }).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 3000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('クーポン利用設定を変更しました', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.getLineStatusV2(summary);
            },
            error => {
              console.log(error.error.message.message);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open('クーポン利用設定の変更に失敗しました', 'OK', cfg);
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public post5GLineStatus(summary): void {
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '5Gの利用設定',
        message: `${summary.v2_status_data.five_g ? '使用中' : '未使用'} を ${summary.v2_status_data.five_g ? '未使用' : '使用中'} に変更しますか？`
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiPost5GLineStatus({
            contract_product_id: summary.contract_product_id,
            status: summary.v2_status_data.five_g ? 'disable' : 'enable'
          }).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 3000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('5G利用設定を変更しました', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.getLineStatusV2(summary);
            },
            error => {
              console.log(error.error.message.message);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open('5G利用設定変更に失敗しました', 'OK', cfg);
            }
          );
        } else if (result.closeby === 'cancel') {
        } else {
        }
      }
    });
  }

  public onChangeLineStatusClicked(product: ContractProduct): void {
    console.log('onChangeLineStatusClicked', product);
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '回線状態の変更',
        message: '状態を' + (product.coupon_status.toUpperCase() == 'USING' ? '利用中' : '強制中断中') + 'から' + (product.coupon_status.toUpperCase() == 'USING' ? '強制中断中' : '利用中') + 'に変更しますか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiSetLineStatus({ contract_product_id: product.contract_product_id, coupon_stop_status: product.coupon_status.toUpperCase() == 'USING' ? 'STOPPED' : 'USING' }).subscribe(
            response => {
              product.coupon_status = product.coupon_status.toUpperCase() == 'USING' ? 'STOPPED' : 'USING';
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('回線状態を変更しました.', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              const error_message = AuthService.getErrorMessage(error);
              if (error_message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(error.error.message.message, 'OK', cfg);
              }
            }
          );
        }
      }
    });
  }


  public getListCharge(productId: string): void {
    this.visibleChargeList[productId] = true;
    if (!this.disableExpansionMap[productId]) {
      this.disableExpansionMap[productId] = true;
    }
    console.log()
  }

  public toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
    console.log('dropdownVisible:', this.dropdownVisible);
  }

  public isChargeListVisible(productId: string): boolean {
    return !!this.visibleChargeList[productId];
  }


  public selectItem(item: string): void {
    let displayValue: string;
    switch (item) {
      case 'MNP Issue':
        displayValue = 'MNP';
        break;
      case 'SIM Reissue':
        displayValue = 'REISSUE';
        break;
      case 'eSIM切り替え':
        displayValue = 'ESIM';
        break;
      case 'Loss fee':
        displayValue = 'LOSTFEE';
        break;
      case 'Damage fee':
        displayValue = 'DamageFee';
        break;
      case 'TOPUP':
        displayValue = 'TOPUP';
        break;
      case 'Other':
        displayValue = 'OTHER';
        break;
    }
    // const contractCode = this.contract_content.contract_summary.contract_code.replace(/-/g, '');
    const contractCode = this.contract_content.contract_summary.contract_code;
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    const gmoOrderIdVaule = `${contractCode}${displayValue}${formattedDate}`;
    console.log(`${contractCode}${displayValue}${formattedDate}`);
    if (this.GmoformGroup.get('orderIdInput')) {
      this.GmoformGroup.get('orderIdInput')!.setValue(gmoOrderIdVaule);
      this.cdr.detectChanges(); 
    }
    this.selectedItem = item;
    this.selectedDropdownType = item;
    this.isItemSelected = true;
    this.dropdownVisible = false;
    this.showDropdownMNP = false;
    this.showDropdownSIM = false;
    this.showDropdownESIM = false;

    if (this.selectedValues[this.selectedDropdownType] !== undefined) {
      (this.selectedValues as any)[this.selectedDropdownType] = '';
    }
    this.taxValue = 0;
    this.memoContent = '';
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isInside = target.closest('.item-selector');
    if (!isInside) {
      this.dropdownVisible = false;
    }
  }

  selectOption(value: string, type: string): void {
    this.selectedValues[type] = Number(value);
    this.blurHandler(type);
    this.calculateTax(type);
  }

  showDropdown(type: string): void {
    this.selectedDropdownType = type;

    if (type === 'MNP Issue') {
      this.showDropdownMNP = true;
    } else if (type === 'SIM Reissue') {
      this.showDropdownSIM = true;
    } else if (type === 'eSIM切り替え') {
      this.showDropdownESIM = true;
    }
  }
  
  blurHandler(type: string): void {
    if (type === 'MNP Issue') {
      this.showDropdownMNP = false;
    } else if (type === 'SIM Reissue') {
      this.showDropdownSIM = false;
    } else if (type === 'eSIM切り替え') {
      this.showDropdownESIM = false;
    }
  }

  calculateTax(type: string): void {
    let charge = 0;
    charge = Number(this.selectedValues[type]) || 0;
    this.taxValue = Math.floor(charge + charge * 0.1);
  }


  public onClickChargeDialog(contract_content: any): void {
    console.log(this.contract_content);

    let dialogData: any;
    if (this.contract_content.contract_properties[3].value === "CREDIT CARD") {
      dialogData = {
        title: '【チャージの確認】',
        message: `チャージします。よろしいですか？`
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '30rem',
        hasBackdrop: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.closeby === 'ok') {
          this.charge_recursive(contract_content);
        }
      });
    } else if (this.contract_content.contract_properties[3].value === "SMARTPIT") {
      dialogData = {
        title: '【支払い締切日】',
      };
      const dialogRef = this.dialog.open(SmartpitDialogComponent, {
        width: '30rem',
        hasBackdrop: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.closeby === 'ok') {
          this.auth_service.lumpSumPayment({ 
            contract_product_id: this.contract_content.product_properties[0].contract_product_id,
            charge_total: parseFloat(this.formGroup.get('chargeInput').value),
            payment_deadline: result.payment_deadline,
            pay_method: this.contract_content.contract_properties[3].value,
            gmo_order_id: this.GmoformGroup.get('orderIdInput').value,
            note: this.memoContent,
          }).subscribe({
            next: response => {
              console.log('Success:', response);
              // this.onClickAdd()
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('CSVファイルが添付されたメールが送信されました。', 'OK', cfg);
              this.cdr.detectChanges();
            },
            error: err => {
              console.error('Error:', err);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open(err.error.message.message, 'OK', cfg);
              console.log(this.formGroup.get('chargeInput').value)
            }
          });
        }
      });
    }
  }

  public onClickAdd(): void {
    if (this.memoContent && this.memoContent.trim() !== '') {
      const contractId = this.contract_content.contract_properties[0].contract_id;
      const param = {
        contract_id: contractId,
        memo: this.memoContent,
        to_be_contract_note: this.to_be_contract_note,
      };
      this.auth_service.apiAddContractMemo(param, this, function(reply: AuthGetReply, context: any) {
        console.log('Memo Successfully saved');
      });
    } else {
      console.log('Memo content is empty, not saving');
    }
  }


  private charge_recursive(contract_content: any): void {
    if (contract_content) {
      if (this.contract_content.contract_properties[3].value === "CREDIT CARD") {
        console.log("Payment method is CREDIT CARD");

        this.auth_service.lumpSumPayment({ 
          contract_product_id: this.contract_content.product_properties[0].contract_product_id,
          charge_total: parseFloat(this.formGroup.get('chargeInput').value),
          pay_method: this.contract_content.contract_properties[3].value,
          gmo_order_id: this.GmoformGroup.get('orderIdInput').value,
          note: this.memoContent,
        }).subscribe(
          response => {
            this.snackBar.dismiss();
            const cfg = new MatSnackBarConfig();
            cfg.duration = 5000;
            cfg.panelClass = ['notify_snackbar', 'success'];
            this.snackBar.open('Chargeされました。', 'OK', cfg);
            this.cdr.detectChanges();
          },
          error => {
            this.snackBar.dismiss();
            const cfg = new MatSnackBarConfig();
            cfg.duration = 5000;
            cfg.panelClass = ['notify_snackbar', 'error'];
            this.snackBar.open(error.error.message.message, 'OK', cfg);
            console.log(this.formGroup.get('chargeInput').value)
          }
        );
      } 
    }
  }

  public cancelListCharge(productId: string): void {
    this.visibleChargeList[productId] = false;
    (this.selectedValues as any)[this.selectedDropdownType] = '';
    this.formGroup.reset();
    this.selectedItem = '';
    this.GmoformGroup.reset();
    this.memoContent = '';
    this.disableExpansionMap[productId] = false;
  }


  public getContractProductExpanded(contract_product_id: number): boolean {
    if (this.disableExpansionMap[contract_product_id]) {
      return false;
    }
    const founds = this.contract_product_expand_states.filter(function (
      expand_status: ContractProductExpandState
    ) {
      return expand_status.contract_product_id === contract_product_id;
    });
    if (founds.length === 0) {
      return false;
    }
    return founds[0].panel_open_state;
  }

  public setContractProductExpanded(
    contract_product_id: number,
    panel_open_state: boolean
  ): void {
    const founds = this.contract_product_expand_states.filter(function (
      expand_status: ContractProductExpandState
    ) {
      return expand_status.contract_product_id === contract_product_id;
    });
    if (founds.length > 0) {
      founds[0].panel_open_state = panel_open_state;
    } 
  }

  public getNamePart(e_mail: string): string {
    if (!Utils.isValue(e_mail)) {
      return '';
    }
    return e_mail.split('@')[0];
  }
  public getDisplayDateString(offsetZero: string): string {
    if (!Utils.isValue(offsetZero) || offsetZero.length === 0) {
      return '';
    }
    return Utils.getDisplayDateString(offsetZero);
  }

  public getMBytesExpressionMB(mbytes: number): string {
    return mbytes + ' MB';
  }

  public getMBytesExpression(bytes: number): string {
    return (bytes / 1048576).toFixed(3) + ' MB';
  }

  public isSIMProduct(item: any): boolean {
    if (item.title.indexOf('SIM') > 0 || item.title.indexOf('SUSPEND')) {
      return true;
    }
    return false;
  }

  public phoneNumber(item: any): any {
    const properties = this.contractProductProperties(item.contract_product_id);
    const property = properties.find(prop => { return prop.key === 'KPD LINE NUMBER' });
    if (property) {
      return {
        isSIM: true,
        telno: property.value
      }
    }
    return {
      isSIM: false,
      telno: null
    };
  }

  public isReissueESIMProduct(item: any): boolean {
    if (item.title.indexOf('eSIM') > 0) {
      return true;
    }
    return false;
  }

  private notifyPropertyBlank(prop_title: string, property: any): void {
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 5000;
    cfg.panelClass = ['notify_snackbar', 'error'];
    if (!property) {
      this.snackBar.open(`${prop_title} が見つかりません`, 'OK', cfg);
    }
    else {
      this.snackBar.open(`${prop_title} の値が入力されていません`, 'OK', cfg);
    }
  }

  public onReissueESIM(item: any): void {
    // console.log('ContractComponent::onReissueESIM::', item);
    const item_properties = this.contract_content.product_properties.filter(property => { return property.contract_product_id === item.contract_product_id })
    const kpo_prop = item_properties.find(prop => { return prop.key === 'KPD SERVICE CODE' });
    const linenumber_prop = item_properties.find(prop => { return prop.key === 'KPD LINE NUMBER' });
    console.log(item_properties);
    if (!kpo_prop || !kpo_prop.value || kpo_prop.value.length == 0) {
      this.notifyPropertyBlank('回線サービスコード', kpo_prop);
      return;
    }

    if (!linenumber_prop || !linenumber_prop.value || linenumber_prop.value.length == 0) {
      this.notifyPropertyBlank('回線番号', linenumber_prop);
      return;
    }

    const eid_prop = item_properties.find(prop => { return prop.key === 'ESIM EID' });
    if (!eid_prop || !eid_prop.value || eid_prop.value.length == 0) {
      this.notifyPropertyBlank('ESIM EID', eid_prop);
      return;
    }

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'eSIMの再発行',
        message: `${item.description}を本日開始で再発行しますか？<br/>` +
          `回線サービスコード : ${kpo_prop.value}<br/>` +
          `回線番号 : ${linenumber_prop.value}<br/>` +
          `ESIM EID : ${eid_prop.value}<br/>` +
          `eSIM再発行受付時間は 10:00-18:00 です`
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiPostReissueEsim({ item_id: item.contract_product_id, eid: eid_prop.value }).subscribe(
            response => {
              console.log("success", response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 3000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('eSIMの再発行を行いました', 'OK', cfg);
              this.confirm_dialog_ref.close();
              this.refresh();
            },
            error => {
              console.log("error", error);
              if (error.error.message !== undefined) {
                this.snackBar.dismiss();
                const cfg = new MatSnackBarConfig();
                cfg.duration = 5000;
                cfg.panelClass = ['notify_snackbar', 'error'];
                this.snackBar.open(`eSIMの再発行に失敗しました : ${error.error.message}`, 'OK', cfg);
              }
            }
          );
        }
      }
    });
  }

  public openOrderRequestMNPNumberDialog(item: any): void {
    const item_properties = this.contract_content.product_properties.filter(property => { return property.contract_product_id === item.contract_product_id })
    const telno_prop = item_properties.find(prop => { return prop.key === 'KPD LINE NUMBER' });
    const mnp_name_prop = item_properties.find(prop => { return prop.key === 'MNP CONTRACT NAME' });
    const mnp_name_kana_prop = item_properties.find(prop => { return prop.key === 'MNP CONTRACT NAME KANA' });
    const mnp_birth_prop = item_properties.find(prop => { return prop.key === 'MNP CONTRACT DATE OF BIRTH' });
    const mnp_sex_prop = item_properties.find(prop => { return prop.key === 'MNP CONTRACT SEX' });
    this.confirm_dialog_ref = this.dialog.open(OrderRequestMnpNumberDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        contract_product_id: item.contract_product_id,
        telno: telno_prop.value,
        mnp_name: mnp_name_prop.value,
        mnp_name_kana: mnp_name_kana_prop.value,
        mnp_birth: mnp_birth_prop.value,
        mnp_sex: mnp_sex_prop.value
      }
    });
  }
}
