<div class="app-order-request-mnp-number-dialog">
  <h3 style="margin-top: 0; font-size: 1.5rem;">MNP転出番号発行</h3>
  <table style="width: 100%; line-height: 2;">
    <tr>
      <td style="width: 55%">回線番号</td>
      <td>{{data.telno}}</td>
    </tr>
    <tr>
      <td style="width: 55%">MNP契約者名</td>
      <td>{{data.mnp_name}}</td>
    </tr>
    <tr>
      <td style="width: 55%">MNP契約者名(カナ)</td>
      <td>{{data.mnp_name_kana}}</td>
    </tr>
    <tr>
      <td style="width: 55%">MNP契約者生年月日</td>
      <td>{{data.mnp_birth}}</td>
    </tr>
    <tr>
      <td style="width: 55%">MNP契約者性別(male/female)</td>
      <td>{{data.mnp_sex}}</td>
    </tr>
  </table>
  <div style="text-align: right; margin-top: 1.5rem;">
    <button mat-flat-button class="yes" (click)="onOkClicked($event)">Apply</button>
    <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
  </div>
</div>
