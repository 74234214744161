<mat-form-field appearance="fill" style="width: 250px;">
  <mat-label>MNPポートアウト年月</mat-label>
  <mat-select [(value)]="selected_month" (selectionChange)="toggleCancelLineList()">
    <mat-option *ngFor="let month of this.getPastThreeMonths()" [value]="month">
      {{ month }}
    </mat-option>
  </mat-select>
</mat-form-field>
<button mat-flat-button class="yes" (click)="getMNPCancelLineList()" [disabled]="isLoading" [ngClass]="{'disabled': isLoading}">
  {{ cancel_line_list.data.length === 0 ? '取得' : '再取得' }}
</button>
<button mat-flat-button class="yes" (click)="openDialogSetProductsEndDates()" [disabled]="isLoading || cancel_line_list.data.length === 0" [ngClass]="{'disabled': isLoading || cancel_line_list.data.length === 0}">
  利用終了日一括挿入
</button>

<table class="operation" style="width: 100%">
  <tr>
    <td class="charging-list-paginator" colspan="3">
      <mat-paginator class="charging-list-paginator" [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]"
        showFirstLastButtons></mat-paginator>
    </td>
  </tr>
</table>

<table mat-table [dataSource]="cancel_line_list" matSort class="cancel-line-list-table">
  <ng-container matColumnDef="contract_code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約コード</th>
    <td mat-cell *matCellDef="let sims">
      <a [href]="operationdb_url + '/operation/contract?contract_id=' + sims.contract_id" target="_blank">{{ sims.contract_code }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="contract_end_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>契約終了日</th>
    <td mat-cell *matCellDef="let sims" class="contract-end_at">
      {{ datePart(sims.contract_end_at) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="products">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>アクティブな契約商品</th>
    <td mat-cell *matCellDef="let sims" [innerHTML]="productsString(sims.products)" class="products"></td>
  </ng-container>

  <ng-container matColumnDef="user_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>ユーザー名</th>
    <td mat-cell *matCellDef="let sims" class="user_name">{{ sims.user_name }}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
    <td mat-cell *matCellDef="let sims" class="email">{{ sims.email }}</td>
  </ng-container>

  <ng-container matColumnDef="pay_method">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>支払方法</th>
    <td mat-cell *matCellDef="let sims" class="email">{{ sims.pay_method }}</td>
  </ng-container>

  <ng-container matColumnDef="telno">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>回線番号</th>
    <td mat-cell *matCellDef="let sims" class="telno">
      <a [href]="'https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/index.cgi?TELNO=' + sims.telno + '&STAGE=1'" target="_blank">
        {{ sims.telno }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="contract_product_end_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>利用終了日</th>
    <td mat-cell *matCellDef="let sims" class="contract_product_end_at">
      <!-- <a [href]="getTelnoUrl(data.telno)" target="_blank">{{sims.telno}}</a> -->
      {{ datePart(sims.contract_product_end_at) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cancel_date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>回線解約日</th>
    <td mat-cell *matCellDef="let sims" class="cancel_date">
      {{ sims.cancel_date }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
  <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
</table>