import 'hammerjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule, routingComponents } from './app-routing.module';

// Angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatIconModule,
  MatTooltipModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatDialogModule,
  MatNativeDateModule,
  NativeDateModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MAT_DATE_FORMATS,
  ShowOnDirtyErrorStateMatcher,
  MatProgressBarModule,
  MatGridListModule,
  MatMenuModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatExpansionModule,
} from '@angular/material';

import { AppComponent } from './app.component';
import { ListProductTypesComponent } from './components/list/list-product-types/list-product-types.component';
import { CreateProductTypeComponent } from './components/create/create-product-type/create-product-type.component';
import { ListProductPropertiesComponent } from './components/list/list-product-properties/list-product-properties.component';
import { CreateProductPropertyComponent } from './components/create/create-product-property/create-product-property.component';
import { SelectProductPropertyTypeComponent } from './components/select/select-product-property-type/select-product-property-type.component';

import { HttpAuthInterceptor } from './interceptors';
import { FlashMessageComponent } from './components/misc/flash-message/flash-message.component';
import { EditorListProductTypesComponent } from './components/editor/editor-list-product-types/editor-list-product-types.component';
import { SignupComponent } from './components/page/signup/signup.component';
import { InvoicesComponent } from './components/page/invoices/invoices.component';
import { ListContractsComponent } from './components/list/list-contracts/list-contracts.component';
import { ListInvoicesComponent } from './components/list/list-invoices/list-invoices.component';
import { ContractsComponent } from './components/page/contracts/contracts.component';
import { ChargedComponent } from './components/page/charged/charged.component';
import { InvoiceComponent } from './components/page/invoice/invoice.component';
import { ChargedInvoicesComponent } from './components/misc/charged-invoice/charged-invoice.component';
// dialogs
import { GenerateInvoiceConfirmDialogComponent } from './components/dialog/generate-invoice-confirm-dialog/generate-invoice-confirm-dialog.component';
import { GenerateInvoiceProgressDialogComponent } from './components/dialog/generate-invoice-progress-dialog/generate-invoice-progress-dialog.component';
import { SendInvoiceConfirmDialogComponent } from './components/dialog/send-invoice-confirm-dialog/send-invoice-confirm-dialog.component';
import { SendInvoiceProgressDialogComponent } from './components/dialog/send-invoice-progress-dialog/send-invoice-progress-dialog.component';
import { AddContractMemoDialogComponent } from './components/dialog/add-contract-memo-dialog/add-contract-memo-dialog.component';
import { EditProductPropertyDialogComponent } from './components/dialog/edit-product-property-dialog/edit-product-property-dialog.component';
import { EditContractPropertyDialogComponent } from './components/dialog/edit-contract-property-dialog/edit-contract-property-dialog.component';
import { EditUserPropertyDialogComponent } from './components/dialog/edit-user-property-dialog/edit-user-property-dialog.component';
import { ContractComponent } from './components/page/contract/contract.component';
import { ListContractMemosComponent } from './components/list/list-contract-memos/list-contract-memos.component';
import { FileImportComponent } from './components/page/file-import/file-import.component';

import { EmailErrorStateMatcher } from './helpers/email-error-state-matcher';
import { EditPropertyDialogBaseComponent } from './components/dialog/edit-property-dialog-base/edit-property-dialog-base.component';
import { EditInvoiceProductPropertyDialogComponent } from './components/dialog/edit-invoice-product-property-dialog/edit-invoice-product-property-dialog.component';
import { AddInvoiceProductPropertyDialogComponent } from './components/dialog/add-invoice-product-property-dialog/add-invoice-product-property-dialog.component';
import { FileExportComponent } from './components/page/file-export/file-export.component';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { SelectDialogComponent } from './components/dialog/select-dialog/select-dialog.component';
import { DateDialogComponent } from './components/dialog/date-dialog/date-dialog.component';
import { SearchComponent } from './components/page/search/search.component';
import { SearchFastComponent } from './components/page/search-fast/search-fast.component';
import { SearchTextInUsersComponent } from './components/list/search-text-in-users/search-text-in-users.component';
import { SearchTextInContractsComponent } from './components/list/search-text-in-contracts/search-text-in-contracts.component';
import { NewContractDialogComponent } from './components/dialog/new-contract-dialog/new-contract-dialog.component';
import { ShipworkComponent } from './components/page/shipwork/shipwork.component';
import { DashboardComponent } from './components/page/dashboard/dashboard.component';
import { ListContractPayHistoriesComponent } from './components/list/list-contract-pay-histories/list-contract-pay-histories.component';
import { ChargesComponent } from './components/page/charges/charges.component';
import { ListChargesComponent } from './components/list/list-charges/list-charges.component';
import { EditContractEmailDialogComponent } from './components/dialog/edit-contract-email-dialog/edit-contract-email-dialog.component';
import { AdminToolComponent } from './components/page/admin-tool/admin-tool.component';
import { EditProductReturnInformationDialogComponent } from './components/dialog/edit-product-return-information-dialog/edit-product-return-information-dialog.component';
import { UtilitiesComponent } from './components/page/utilities/utilities.component';
import { ContractPrintComponent } from './components/page/contract-print/contract-print.component';
import { ParentalConsentComponent } from './components/page/parental-consent/parental-consent.component';
import { ReferralPointsComponent } from './components/page/refferal-points/refferal-points.component';
import { FiberScheduleComponent } from './components/page/fiber-schedule/fiber-schedule.component';
import { FiberSchedulesComponent } from './components/page/fiber-schedules/fiber-schedules.component';
import { TopupTicketsComponent } from './components/page/topup-tickets/topup-tickets.component';
import { ListFiberSchedulesComponent } from './components/list/list-fiber-schedules/list-fiber-schedules.component';
import { GenerateTopupTicketDialogComponent } from './components/dialog/generate-topup-ticket-dialog/generate-topup-ticket-dialog.component';
import { VarDirective } from './ng-var.directive';
import { ListActivateComponent } from './components/list/list-activate/list-activate.component';
import { ImageDialogComponent } from './components/dialog/image-dialog/image-dialog.component';
import { OrderRequestMnpNumberDialogComponent } from './components/dialog/order-request-mnp-number-dialog/order-request-mnp-number-dialog.component';
import { ListMnpPortOutComponent } from './components/list/list-mnp-port-out/list-mnp-port-out.component';
import { SmartpitDialogComponent } from './components/dialog/smartpit-dialog/smartpit-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    FlashMessageComponent,
    EditorListProductTypesComponent,
    ListProductTypesComponent,
    CreateProductTypeComponent,
    ListProductPropertiesComponent,
    CreateProductPropertyComponent,
    SelectProductPropertyTypeComponent,
    // PortalComponent,
    // OperationComponent
    routingComponents,
    SignupComponent,
    InvoicesComponent,
    ListContractsComponent,
    ListInvoicesComponent,
    ContractsComponent,
    ChargedComponent,
    InvoiceComponent,
    ChargedInvoicesComponent,
    GenerateInvoiceConfirmDialogComponent,
    GenerateInvoiceProgressDialogComponent,
    SendInvoiceConfirmDialogComponent,
    SendInvoiceProgressDialogComponent,
    ContractComponent,
    ListContractMemosComponent,
    AddContractMemoDialogComponent,
    FileImportComponent,
    EditProductPropertyDialogComponent,
    EditContractPropertyDialogComponent,
    EditUserPropertyDialogComponent,
    EditPropertyDialogBaseComponent,
    EditInvoiceProductPropertyDialogComponent,
    AddInvoiceProductPropertyDialogComponent,
    FileExportComponent,
    ConfirmDialogComponent,
    SelectDialogComponent,
    DateDialogComponent,
    SearchComponent,
    SearchFastComponent,
    SearchTextInUsersComponent,
    SearchTextInContractsComponent,
    NewContractDialogComponent,
    ShipworkComponent,
    DashboardComponent,
    ListContractPayHistoriesComponent,
    ChargesComponent,
    ListChargesComponent,
    EditContractEmailDialogComponent,
    AdminToolComponent,
    EditProductReturnInformationDialogComponent,
    UtilitiesComponent,
    ContractPrintComponent,
    ParentalConsentComponent,
    ReferralPointsComponent,
    FiberScheduleComponent,
    FiberSchedulesComponent,
    ListFiberSchedulesComponent,
    TopupTicketsComponent,
    GenerateTopupTicketDialogComponent,
    VarDirective,
    ListActivateComponent,
    ImageDialogComponent,
    OrderRequestMnpNumberDialogComponent,
    ListMnpPortOutComponent,
    SmartpitDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatProgressBarModule,
    MatGridListModule,
    MatMenuModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    NativeDateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: false
      }
    },
    {
      provide: EmailErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 }
    }
  ],
  // entryComponents: is needed to use mat-dialog
  entryComponents: [
    GenerateInvoiceConfirmDialogComponent,
    GenerateInvoiceProgressDialogComponent,
    SendInvoiceConfirmDialogComponent,
    SendInvoiceProgressDialogComponent,
    AddContractMemoDialogComponent,
    EditProductPropertyDialogComponent,
    EditContractPropertyDialogComponent,
    EditUserPropertyDialogComponent,
    EditContractEmailDialogComponent,
    EditInvoiceProductPropertyDialogComponent,
    AddInvoiceProductPropertyDialogComponent,
    ConfirmDialogComponent,
    SelectDialogComponent,
    DateDialogComponent,
    NewContractDialogComponent,
    EditProductReturnInformationDialogComponent,
    GenerateTopupTicketDialogComponent,
    ImageDialogComponent,
    OrderRequestMnpNumberDialogComponent,
    SmartpitDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
