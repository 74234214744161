<table style="width: 100%">
  <tr>
      <td colspan="2">
        {{data.title}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-form-field appearance="fill" style="width: 100%;">
        <!-- <mat-label>支払い締切日</mat-label> -->
        <input
          matInput
          type="date"
          [(ngModel)]="deadline"
          (change)="fromCofferdamDate($event.target.value)"
        />
        <mat-hint>支払い締切日を選択してください。</mat-hint>
      </mat-form-field>
        <div [innerHTML]="data.message"></div>
    </td>
  </tr>
  <tr>
      <td style="text-align: right">
        <button mat-flat-button class="warn" (click)="onOkClicked($event)">OK</button>
        <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
      </td>
  </tr>
</table>


