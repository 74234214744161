import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, } from '@angular/material';
import { ListContractPayHistoriesComponent } from '../../list/list-contract-pay-histories/list-contract-pay-histories.component';
import { AuthService } from 'src/app/services/auth';


export class SmartpitDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-smartpit-dialog',
  templateUrl: './smartpit-dialog.component.html',
  styleUrls: ['./smartpit-dialog.component.scss']
})
export class SmartpitDialogComponent implements OnInit {
  public deadline: string = '';
  public form: FormGroup;
  public fb: FormBuilder;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SmartpitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SmartpitDialogData,
    ) { 
      console.log('Dialog data:', data);
  }

  ngOnInit() {
  }

  public fromCofferdamDate(value: string): void {
    console.log('選択された支払い締切日:', value);
    this.deadline = value;
  }
  
  public onCancelClicked(event): void {
    console.log('ConfirmDialog::onCancelClicked -start');
    this.dialogRef.close({
      closeby: 'cancel'
    });
  }

  public onOkClicked(event): void {
    console.log('ConfirmDialog::onOkClicked -start');
    this.dialogRef.close({
      closeby: 'ok',
      payment_deadline: this.deadline
    });
  }
}
