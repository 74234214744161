import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatCheckboxChange,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class Product {
  title: string;
  device_code: string;
}

export class MNPCancelLineList {
  cancel_date: string;
  contract_code: string;
  contract_end_at: string;
  contract_id: number;
  contract_product_end_at: string;
  contract_product_id: number;
  telno: string;
  products: Product[];
  pay_method: string;
  email: string;
  user_name: string;
}

export interface MNPCancelLineListResponse {
  data: {
    sims: MNPCancelLineList[];
  };
}

export class SetProductsEndDates {
  contract_product_id: number;
  contract_product_end_at: string;
  contract_end_at: string;
}

export interface SetProductsEndDatesResponse {
  data: {
    set_products_end_dates: SetProductsEndDates[];
  },
  message: {
    status: string;
    level: string;
    title: string;
    message: string;
    duration : number;
  };
}

@Component({
  selector: 'app-list-mnp-port-out',
  templateUrl: './list-mnp-port-out.component.html',
  styleUrls: ['./list-mnp-port-out.component.scss']
})
export class ListMnpPortOutComponent implements OnInit {
  selectable_months: string[];
  selected_month: string;
  isLoading: boolean = false;
  display_columns: string[];
  cancel_line_list: MatTableDataSource<MNPCancelLineList>;
  current_month_list: MatTableDataSource<MNPCancelLineList>;
  previous_month_list: MatTableDataSource<MNPCancelLineList>;
  two_months_ago_list: MatTableDataSource<MNPCancelLineList>;
  operationdb_url: string;
  contract_product_ids: number[];

  constructor(
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.display_columns = [
      'contract_code',
      'contract_end_at',
      'products',
      'user_name',
      'email',
      'pay_method',
      'telno',
      'contract_product_end_at',
      'cancel_date',
    ];
    this.cancel_line_list = new MatTableDataSource<MNPCancelLineList>([]);
    this.current_month_list = new MatTableDataSource<MNPCancelLineList>([]);
    this.previous_month_list = new MatTableDataSource<MNPCancelLineList>([]);
    this.two_months_ago_list = new MatTableDataSource<MNPCancelLineList>([]);
    this.operationdb_url = environment.operationDB;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.selectable_months = this.getPastThreeMonths();
    this.selected_month = this.selectable_months[0];
  }

  refresh() {

  }

  getPastThreeMonths(): string[] {
    const currentDate = new Date();
    const months: string[] = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date(currentDate);
      date.setMonth(currentDate.getMonth() - i);
      const monthString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      months.push(monthString);
    }
    return months;
  }

  getMNPCancelLineList(): void {
    const index = this.selectable_months.indexOf(this.selected_month);
    const cancel_month = this.selected_month.replace('-', '');
    const params = { cancel_month: cancel_month };
    this.isLoading = true;
    this.auth_service.apiGetMNPCancelLineList(params).subscribe(
      response => {
        const res = response as MNPCancelLineListResponse;
        const sims = res.data.sims;
        this.cancel_line_list.data = sims as MNPCancelLineList[];
        this.cancel_line_list.sort = this.sort;
        this.cancel_line_list.paginator = this.paginator;
        console.log('this.cancel_line_list:', this.cancel_line_list);

        if (index === 0) {
          this.current_month_list = this.cancel_line_list;
        }
        else if (index === 1) {
          this.previous_month_list = this.cancel_line_list;
        }
        else if (index === 2) {
          this.two_months_ago_list = this.cancel_line_list;
        }
        this.isLoading = false;
      },
      error => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  toggleCancelLineList(): void {
    const index = this.selectable_months.indexOf(this.selected_month)
    if (index === 0) {
      this.cancel_line_list = this.current_month_list;
    }
    else if (index === 1) {
      this.cancel_line_list = this.previous_month_list;
    }
    else if (index === 2) {
      this.cancel_line_list = this.two_months_ago_list;
    }
  }

  openDialogSetProductsEndDates(): void {
    const [year, month_tmp] = this.selected_month.split('-');
    const month = parseInt(month_tmp, 10);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '利用終了日一括挿入',
        message: `${year}年${month}月にMNPポートアウトした商品の利用終了日を${year}年${month}月末日に変更しますか？(利用終了日が入力済みの商品は除く)`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.closeby === 'ok') {
        this.setProductsEndDates();
      }
    });
  }

  setProductsEndDates(): void {
    const contract_product_ids = [];
    const transfer_dates = [];
    this.cancel_line_list.data.forEach(sims => {
      if (sims.contract_product_id) {
        contract_product_ids.push(sims.contract_product_id);
        transfer_dates.push(sims.cancel_date);
      }
    });
    const params = {
      end_month: this.selected_month.replace('-', ''),
      contract_product_ids: contract_product_ids,
      transfer_dates: transfer_dates
    };
    console.log('contract_product_ids:', contract_product_ids);
    console.log('transfer_dates:', transfer_dates);
    this.isLoading = true;
    this.auth_service.apiSetProductsEndDates(params).subscribe(
      response => {
        const res = response as SetProductsEndDatesResponse;
        const set_products_end_dates = res.data.set_products_end_dates as SetProductsEndDates[];
        console.log(response);
        this.updateDisplayEndDates(res);
        this.isLoading = false;
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(res.message.message, 'OK', cfg);
      },
      error => {
        console.error(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  updateDisplayEndDates(response: SetProductsEndDatesResponse) {
    const updatedItems = this.cancel_line_list.data.map(item => {
      const matchingItems = response.data.set_products_end_dates.find(matchingItem => matchingItem.contract_product_id === item.contract_product_id);
      if (matchingItems) {
        return { ...item, contract_product_end_at: matchingItems.contract_product_end_at, contract_end_at: matchingItems.contract_end_at };
      }
      return item;
    });
    this.cancel_line_list.data = updatedItems;
  }

  datePart(datestring: string): string {
    return Utils.getDatePart(datestring, false);
  }

  private productString(product: Product): string {
    let product_string = product.title;
    if (Utils.isValue(product.device_code) && Utils.isValue(product.device_code)) {
      if (product.device_code.toLowerCase() !== 'undefined') {
        product_string = product_string + ' [' + product.device_code + ']';
      }
    }
    return product_string;
  }

  productsString(products: Product[]): string {
    const product_titles = new Array<string>();
    if (products !== null) {
      products.forEach(product => {
        product_titles.push(this.productString(product));
      });
    }
    return Utils.joinStringsToBRLines(product_titles);
  }
}
