import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { LoginReply, AuthGetReply } from '../../models';
import { Utils } from '../../helpers';
import { Keys, Url } from '../../constants';
import { FlashMessageService } from '../misc';
import { Subject, Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public login_after_url: string;
  private _logging_in = new Subject<boolean>();
  public logging_in$ = this._logging_in.asObservable();

  public set logging_in(is_logging_in: boolean) {
    this._logging_in.next(is_logging_in);
  }
  // public authToken: string | null = null;
  private authTokenSubject = new BehaviorSubject<string | null>(null);
  public authToken$ = this.authTokenSubject.asObservable();

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private flash_service: FlashMessageService
  ) {
    this.login_after_url = null;
  }

  // ==========
  // API以外の実装
  // ==========
  public static getErrorMessage(error_response: any): string {
    if (
      Utils.isValue(error_response.error) &&
      Utils.isValue(error_response.error.message) &&
      Utils.isValue(error_response.error.message.message)
    ) {
      return error_response.error.message.message;
    }
    return undefined;
  }

  public getloginUser(): LoginReply {
    const user_json = localStorage.getItem(Keys.LOGIN_USER);
    if (!Utils.isValue(user_json)) {
      return null;
    }
    const user = <LoginReply>JSON.parse(user_json);
    return user;
  }

  public isMemberUser(group: string, fail_url: string): void {
    this.apiIsMemberUser(group, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        if (fail_url && fail_url.length > 0) {
          location.href = fail_url;
        }
      }
    });
  }

  protected setLoginUser(user: LoginReply) {
    localStorage.setItem(Keys.LOGIN_USER, JSON.stringify(user));
    // no security session expire
    const dt = new Date();
    localStorage.setItem(Keys.LAST_OPERATE_TIME, dt.toISOString());
  }

  public signup(user_id: string, password: string, password_confirmation) {
    return (
      this.http
        .post(Url.SIGN_UP, {
          email: user_id,
          password: password,
          password_confirmation: password_confirmation,
        }).
        subscribe(
          response => {
            console.log('AuthService::signup - reply success');
            console.log(response);
          },
          error => {
            console.log('AuthService::signup - reply error');
            console.log(error);
          }
        )
    );
  }

  public login(user_id: string, password: string) {
    return (
      this.http
        .post(Url.LOGIN, {
          email: user_id,
          password: password
        })
        // after .subscribe is needed to activate interceptor... (why?)
        .subscribe(
          response => {
            // devise-jwt : authentication reply
            const auth_reply = <LoginReply>response;
            if (Utils.isValue(auth_reply.auth_token)) {
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(
                'Login success.',
                'OK',
                cfg);
              this.setLoginUser(auth_reply);
              if (this.login_after_url) {
                console.log('AuthService::login - has logon_after_url');
                const login_after_url_copy = this.login_after_url;
                this.login_after_url = null;
                location.href = login_after_url_copy;
              }
              else {
                console.log('AuthService::login - doesn\'t have logon_after_url');
              }
              this.logging_in = true;
            }
          },
          error => {
            const auth_reply = <LoginReply>error.error;
            this.snackBar.dismiss();
            const cfg = new MatSnackBarConfig();
            cfg.duration = 2000;
            cfg.panelClass = ['notify_snackbar', 'error'];
            this.snackBar.open(
              'Login failed',
              'OK',
              cfg);
          }
        )
    );
  }

  public logout() {
    localStorage.removeItem(Keys.LOGIN_USER);
    this.logging_in = this.isLoggingIn();
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 2000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'Logout.',
      'OK',
      cfg);
    console.log('AuthService::logout - redirect to login');
    location.href = '/auth/login';
  }

  public upload(url: string, upload_file: File): Observable<Object> {
    const current_user = this.getloginUser();
    console.log('AuthService::upload : url=' + url);
    console.log('AuthService::upload : upload_file=' + JSON.stringify(upload_file));
    const formData: FormData = new FormData();
    formData.append('upload_file', upload_file, upload_file.name);

    return this.http.post(url, formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  public post(url: string, params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    console.log('AuthService::post : url=' + url);
    // console.log('AuthService::post : current_user=' + JSON.stringify(current_user));
    console.log('AuthService::post : params=' + JSON.stringify(params));
    return this.http.post(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  public put(url: string, params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    console.log('AuthService::put : url=' + url);
    // console.log('AuthService::put : current_user=' + JSON.stringify(current_user));
    console.log('AuthService::put : params=' + JSON.stringify(params));
    return this.http.put(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  public patch(url: string, params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    console.log('AuthService::put : url=' + url);
    // console.log('AuthService::put : current_user=' + JSON.stringify(current_user));
    console.log('AuthService::put : params=' + JSON.stringify(params));
    return this.http.patch(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  public get(url: string): Observable<Object> {
    console.log(`get url : ${url}`);
    if (this.isLoggingIn() === false) {
      return this.http.get(url);
    }
    else {
      const current_user = this.getloginUser();
      return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
    }

  }

  public initialize(): void {
    this.logging_in = this.isLoggingIn();
  }

  public isLoggingIn(): boolean {
    console.log('AuthService::isLoggingIn()');
    const current_user = this.getloginUser();
    if (current_user === null ) {
      return false;
    }

    // no security session expire
    console.log('no Security');
    const dt_str = localStorage.getItem(Keys.LAST_OPERATE_TIME);
    if (!Utils.isValue(dt_str) || dt_str.length === 0) {
      return false;
    }
    const dt = new Date(dt_str);
    const dt_now = new Date();
    if (dt_now.getTime() - dt.getTime() > 6 * 60 * 60 * 1000) {  // 6 hour
    // if (dt_now.getTime() - dt.getTime() > 1 * 2 * 60 * 1000) {  // 2 min
      return false;
    }
    localStorage.setItem(Keys.LAST_OPERATE_TIME, dt.toISOString());
    return true;
  }

  private _apiPost(
    url: string,
    params: any,
    context: any,
    proc_for_reply: any
  ) {
    this.post(url, params).
      subscribe(
        response => {
          this.apiReplyProc(
            <AuthGetReply>response,
            context,
            proc_for_reply
          );
        },
        error => {
          console.log('AuthService::apiGet - reply error - ' + JSON.stringify(error));
          if (Utils.isValue(error.status) && error.status === 401 /* Unauthorized */) {
            this.logout();
          } else {
            this.apiReplyError(error);
          }
        }
      );
  }

  private apiReplyProc(
    reply: AuthGetReply,
    context: any,
    proc_for_reply: any,
  ) {
    this.flash_service.startflash_message(
      'success',
      'info',
      reply.message.title,
      reply.message.message,
      reply.message.duration
    );
    proc_for_reply(reply, context);
  }

  private apiReplyError(error: any) {
    let title;
    let message;
    title = '';
    message = '';
    if (Utils.isValue(error.error)) {
      if (Utils.isValue(error.error.message)) {
        if (Utils.isValue(error.error.message.title)) {
          title = error.error.message.title;
        }
        if (Utils.isValue(error.error.message.message)) {
          message = error.error.message;
        }
      }
    } else {
      if (Utils.isValue(error.statusText)) {
        title = error.statusText;
      }
      if (Utils.isValue(error.message)) {
        message = error.message;
      }
    }
    this.flash_service.startflash_message(
      'error',
      'info',
      title,
      message,
      5000
    );
  }

  /*
   * API : User
   */
  public apiIsExistUser(params): Observable<any> {
    return this.post(Url.IS_EXIST_USER, params);
  }

  public apiIsMemberUser(
    group: string,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.IS_MEMBER_USER, {'group': group}, context, proc_for_reply);
  }
  /*
   * API : User Property
   */
  public apiListUnassignedUserProperties(params): Observable<any> {
    return this.post(Url.LIST_UNASSIGNED_USER_PROPERTIES, params);
  }

  public apiAddUserProperty(params): Observable<any> {
    return this.post(Url.ADD_USER_PROPERTY, params);
  }

  public apiUpdateUserProperty(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.UPDATE_USER_PROPERTY, params, context, proc_for_reply);
  }

  public apiDeleteUserProperty(params): Observable<any> {
    return this.post(Url.DELETE_USER_PROPERTY, params);
  }

  /*
   * API : Contracts ***
   */
  public apiAddContract(params): Observable<any> {
    return this.post(Url.ADD_CONTRACT, params);
  }

  public apiNewContractViaCheckfront(params): Observable<any> {
    return this.post(Url.NEW_CONTRACT_VIA_CHECKFRONT, params);
  }

  public apiListContract(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.LIST_CONTRACTS, params, context, proc_for_reply);
  }

  public apiUpdateFieldContract(params): Observable<any> {
    return this.post(Url.UPDATE_FIELD_CONTRACT, params);
  }

  public apiGetContract(params): Observable<any> {
    return this.post(Url.GET_CONTRACT, params);
  }

  public apiGetLineStatus(params): Observable<any> {
    return this.post(Url.GET_LINE_STATUS, params);
  }

  public apiSetLineStatus(params): Observable<any> {
    return this.post(Url.SET_LINE_STATUS, params);
  }

  public apiGetLineStatusV2(params): Observable<any> {
    return this.get(`${Url.GET_LINE_STATUS_V2}/${params.contract_product_id}`);
  }

  public apiPostLinePauseStatus(params): Observable<any> {
    return this.post(`${Url.POST_LINE_PAUSE_STATUS}/${params.contract_product_id}`, params);
  }

  public apiPostLineCouponStatus(params): Observable<any> {
    return this.post(`${Url.POST_LINE_COUPON_STATUS}/${params.contract_product_id}`, params);
  }

  public apiPost5GLineStatus(params): Observable<any> {
    return this.post(`${Url.POST_5G_STATUS}/${params.contract_product_id}`, params);
  }

  public apiGetReferralPoints(params): Observable<any> {
    return this.get(Url.GET_REFERRAL_POINTS + "/" + params.contract_start_month);
  }

  public apiSetReferralPoints(params): Observable<any> {
    return this.post(Url.SET_REFERRAL_POINTS, params);
  }

  public apiChangeContractOwner(params): Observable<any> {
    return this.post(Url.CHANGE_CONTRACT_OWNER, params);
  }

  public apiEnumContractStatuses(): Observable<any> {
    return this.post(Url.ENUM_CONTRACT_STATUSES, null);
  }

  public apiUpdateContractStatus(params): Observable<any> {
    return this.post(Url.UPDATE_CONTRACT_STATUS, params);
  }

  public apiAggregationDataOfContract(params): Observable<any> {
    return this.post(Url.AGGRIGATION_DATA_OF_CONTRACT, params);
  }

  public apiMonthlySummary(params): Observable<any> {
    return this.post(Url.MONTHLY_SUMMARY, params);
  }

  public apiSetContractProductReturnInformation(params): Observable<any> {
    return this.post(Url.SET_CONTRACT_PRODUCT_RETURN_INFORMATION, params);
  }

  public apiEnumContractProductStatuses(): Observable<any> {
    return this.post(Url.ENUM_CONTRACT_PRODUCT_STATUSES, null);
  }

  public apiUpdateContractProductStatus(params): Observable<any> {
    return this.post(Url.UPDATE_CONTRACT_PRODUCT_STATUS, params);
  }

  public apiCloseLinesAtMonth(params): Observable<any> {
    return this.post(Url.CLOSE_LINES_AT_MONTH, params);
  }

  public apiPrepareDocument(params): Observable<any> {
    console.log('apiPrepareDocument', params);
    return this.post(Url.DOCUMENT_FILE + '/' + params.file_name.substr(0, 11), params);
  }

  /*
   * API : Parental consent ***
   */
  public apiGetParentalConsent(params): Observable<any> {
    console.log('apiGetParentalConsent', params);
    return this.post(Url.PARENTAL_CONSENT, params);
  }

  public apiSetParentalConsent(params): Observable<any> {
    console.log('apiGetParentalConsent', params);
    return this.post(Url.PARENTAL_CONSENT_UPDATE_FIELD, params);
  }

  public apiGetParentalConsentDocuments(params): Observable<any> {
    console.log('apiGetParentalConsent', params);
    return this.post(Url.PARENTAL_CONSENT_DOCUMENTS, params);
  }

  public apiPrepareParentalConsentDocument(params): Observable<any> {
    console.log('apiPrepareParentalConsentDocument', params);
    return this.post(Url.PARENTAL_CONSENT_DOCUMENTS_PREPARE, params);
  }

  /*
   * API : Invoices ***
   */
  public apiListCharges(params): Observable<any> {
    return this.post(Url.LIST_CHARGES, params);
  }

  public apiSendDemandLetter(params): Observable<any> {
    return this.post(Url.SEND_DEMAND_LETTER, params);
  }

  public apiLinePause(params): Observable<any> {
    return this.post(Url.GET_LINE_PAUSE, params).pipe(
      catchError(error => {
        console.error('apiLinePauseのエラー:', error);
        return throwError(error);
      })
    );
  }

  public putApiLinePause(params): Observable<any> {
    return this.post(Url.PUT_LINE_PAUSE, params).pipe(
      catchError(error => {
        console.error('putApiLinePauseのエラー:', error);
        return throwError(error);
      })
    );
  }

  public putSpecialCase(params): Observable<any> {
    return this.patch(Url.PUT_SPECIAL_CASE, params);
  }

  public apiSaveInvoiceNote(params): Observable<any> {
    return this.patch(Url.PUT_INVOICE_NOTE, params);
  }

  /*
   * API : System tasks ***
   */
  public apiListSystemTasks(params): Observable<any> {
    return this.post(Url.LIST_SYSTEM_TASK , params);
  }
  public apiListSystemTaskErrors(params): Observable<any> {
    return this.post(Url.LIST_SYSTEM_TASK_ERROR , params);
  }

  /*
   * API : contract memo ***
   */
  public apiListContractMemos(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.LIST_CONTRACT_MEMOS, params, context, proc_for_reply);
  }

  public apiAddContractMemo(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.ADD_CONTRACT_MEMO, params, context, proc_for_reply);
  }

  public apiDeleteContractMemo(params): Observable<any> {
    return this.post(Url.DELETE_CONTRACT_MEMO, params);
  }

  /*
   * API : admin tools ***
   */
  public apiAdminDeleteContract(params): Observable<any> {
    return this.post(Url.ADMIN_DELETE_CONTRACT, params);
  }

  public apiAdminAddContractPropertyType(params): Observable<any> {
    return this.post(Url.ADMIN_ADD_CONTRACT_PROPERTY_TYPE, params);
  }

  public apiAdminAddProductType(params): Observable<any> {
    return this.post(Url.ADMIN_ADD_PRODUCT_TYPE, params);
  }

  public apiAdminAddProductPropertyType(params): Observable<any> {
    return this.post(Url.ADMIN_ADD_PRODUCT_PROPERTY_TYPE, params);
  }

  public apiAdminApplyProductPropertyTypeToProduct(params): Observable<any> {
    return this.post(Url.ADMIN_APPLY_PRODUCT_PROPERTY_TYPE_TO_PRODUCT, params);
  }

  public apiCheckfrontAPITest(params): Observable<any> {
    return this.post(Url.ADMIN_CHECKFRONT_API_TEST, params);
  }

  /*
   * API : misc ***
   */
  public apiGenerateInvoice(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.GENERATE_INVOICE, params, context, proc_for_reply);
  }

  public apiGenerateInvoices(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.GENERATE_INVOICES, params, context, proc_for_reply);
  }

  public apiInvoiceList(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.LIST_INVOICES_IN_MONTH, params, context, proc_for_reply);
  }

  public apiSendInvoice(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.SEND_INVOICE, params, context, proc_for_reply);
  }

  public apiGetInvoiceContent(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.INVOICE_CONTENT, params, context, proc_for_reply);
  }

  public apiHasInvoiceWithMonth(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.HAS_INVOICE_WITH_MONTH, params, context, proc_for_reply);
  }

  public apiHasInvoicesWithMonth(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.HAS_INVOICES_WITH_MONTH, params, context, proc_for_reply);
  }

  public apiGetUnassignedInvoiceContentProperties(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.LIST_UNASSIGNED_INVOICE_CONTENT_PROPERTIES, params, context, proc_for_reply);
  }

  public apiAddInvoiceContentProperty(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.ADD_INVOICE_CONTENT_PROPERTY, params, context, proc_for_reply);
  }

  public apiSetInvoiceContentProperty(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.SET_INVOICE_CONTENT_PROPERTY, params, context, proc_for_reply);
  }

  public apiRequestChargeInvoiceList(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.REQUEST_CHARGE_INVOICE_LIST, params, context, proc_for_reply);
  }

  public apiRequestInvoicePointsList(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.REQUEST_INVOICE_POINTS_LIST, params, context, proc_for_reply);
  }

  public apiRequestReferralPointsList(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.REQUEST_REFERRAL_POINTS_LIST, params, context, proc_for_reply);
  }

  public apiRequestMonthlyList(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    console.log(params);
    this.get(`${Url.REQUEST_MONTHLY_LIST_BASE}/${params.action}`).subscribe(
      response => {
        this.apiReplyProc(
          <AuthGetReply>response,
          context,
          proc_for_reply
        );
      },
      error => {
        console.log('AuthService::get - reply error - ' + JSON.stringify(error));
        if (Utils.isValue(error.status) && error.status === 401 /* Unauthorized */) {
          this.logout();
        } else {
          this.apiReplyError(error);
        }
      }
    );
  }

  public apiSagawaLabelData(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.SAGAWA_LABEL_DATA, params, context, proc_for_reply);
  }

  public apiEHiden3LabelData(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.EHIDEN3_LABEL_DATA, params, context, proc_for_reply);
  }

  public apiAirportLabelData(params: any): Observable<any> {
    return this.post(Url.AIRPORT_LABEL_DATA, params);
  }

  /*
   * API : Product Types ***
   */
  public apiProductTypesList(
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ) {
    this._apiPost(Url.PRODUCT_TYPES_LIST, null, context, proc_for_reply);
  }

  /*
   * API : Product Property Types ***
   */
  public apiCreateProductType(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.CREATE_PRODUCT_TYPE, params, context, proc_for_reply);
  }

  public apiDeleteProductType(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.DELETE_PRODUCT_TYPE, params, context, proc_for_reply);
  }

  public apiSelectableProductPropertyType(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.SELECTABLES_PRODUCT_PROPERTY_TYPES, params, context, proc_for_reply);
  }

  public apiProductPropertyesInProduct(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.PRODUCT_PROPERTIES_IN_PRODUCT, params, context, proc_for_reply);
  }

  public apiCreateProductProperty(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.CREATE_PRODUCT_PROPERTY, params, context, proc_for_reply);
  }

  public apiDeleteProductProperty(
    params: any,
    context: any, /* this of function */
    proc_for_reply: any,  /* function */
  ): void {
    this._apiPost(Url.DELETE_PRODUCT_PROPERTY, params, context, proc_for_reply);
  }

  public apiSetContractProductStartAt(params): Observable<any> {
    return this.post(Url.SET_CONTRACT_PRODUCT_START_AT, params);
  }

  public apiSetContractProductEndAt(params): Observable<any> {
    return this.post(Url.SET_CONTRACT_PRODUCT_END_AT, params);
  }

  public apiSetContractProductProperty(params): Observable<any> {
    return this.post(Url.SET_CONTRACT_PRODUCT_PROPERTY, params);
  }

  public apiRemoveContractProduct(params: any): Observable<any> {
    return this.post(Url.REMOVE_CONTRACT_PRODUCT, params);
  }

  public apiChangeFiberType(params: any): Observable<any> {
    return this.post(Url.POST_CHANGE_FIBER_TYPE, params);
  }

  public apiGetProductList(): Observable<any> {
    return this.post(Url.LIST_PRODUCTS, {});
  }

  public apiAddContractProduct(params): Observable<any> {
    return this.post(Url.ADD_CONTRACT_PRODUCT, params);
  }

  public apiGetUnassignedContractProperties(params): Observable<any> {
    return this.post(Url.LIST_UNASSIGNED_CONTRACT_PROPERTIES, params);
  }

  public apiAddContractProperty(params): Observable<any> {
    return this.post(Url.ADD_CONTRACT_PROPERTY, params);
  }

  public apiRemoveContractProperty(params): Observable<any> {
    return this.post(Url.REMOVE_CONTRACT_PROPERTY, params);
  }

  public apiGetUnassignedContractProductProperties(params): Observable<any> {
    return this.post(Url.LIST_UNASSIGNED_CONTRACT_PRODUCT_PROPERTIES, params);
  }

  public apiAddContractProductProperty(params): Observable<any> {
    return this.post(Url.ADD_CONTRACT_PRODUCT_PROPERTY, params);
  }

  public apiSearchTextInUsers(params): Observable<any> {
    return this.post(Url.SEARCH_TEXT_IN_USERS, params);
  }

  public apiSearchTextFast(params): Observable<any> {
    return this.post(Url.SEARCH_TEXT_FAST, params);
  }

  public apiSearchText(params): Observable<any> {
    return this.post(Url.SEARCH_TEXT, params);
  }

  public apiSearchTextInContracts(params): Observable<any> {
    return this.post(Url.SEARCH_TEXT_IN_CONTRACTS, params);
  }

  public apiGetShippingContracts(params): Observable<any> {
    return this.post(Url.LIST_SHIPPING_CONTRACTS, params);
  }

  public apiAssignSimID(params): Observable<any> {
    return this.post(Url.ASSIGN_SIM_ID, params);
  }

  public apiActivateSim(params): Observable<any> {
    return this.post(Url.ACTIVATE_SIM, params);
  }

  public apiUpdateContractProperty(params): Observable<any> {
    return this.post(Url.UPDATE_CONTRACT_PROPERTY, params);
  }

  public apiUpdateContractProductProperty(params): Observable<any> {
    return this.post(Url.UPDATE_CONTRACT_PRODUCT_PROPERTY, params);
  }

  public apiSetInvoiceSendStatus(params): Observable<any> {
    return this.post(Url.SET_INVOICE_SEND_STATUS, params);
  }

  public apiSetInvoicePayStatus(params): Observable<any> {
    return this.post(Url.SET_INVOICE_PAY_STATUS, params);
  }

  public apiSetInvoicePayMethod(params): Observable<any> {
    return this.post(Url.SET_INVOICE_PAY_METHOD, params);
  }

  public apiChargeUnpaidInvoice(params): Observable<any> {
    return this.post(Url.CHARGE_UNPAID_INVOICE, params);
  }

  public apiContractPayHistory(params): Observable<any> {
    return this.post(Url.LIST_CONTRACT_PAY_HISTORIES, params);
  }

  public apiSendShippingNotify(params): Observable<any> {
    return this.post(Url.SEND_SHIPPING_NOTIFY, params);
  }

  public apiGetFiberSchedules(): Observable<any>{
    return this.get(`${Url.GET_FIBER_SCHEDULES}`);
  }

  public apiGetFiberSchedule(contract_product_id): Observable<any> {
    return this.get(`${Url.GET_FIBER_SCHEDULE}/${contract_product_id}`);
  }

  public apiGetFiberScheduleProperty(contract_product_id, property_key): Observable<any> {
    return this.get(`${Url.GET_FIBER_SCHEDULE_PROPERTY}/${contract_product_id}/${property_key}`);
  }

  public apiPutFiberScheduleProperty(params): Observable<any> {
    return this.put(Url.PUT_FIBER_SCHEDULE_PROPERTY, params);
  }

  public apiPostFiberGMORegistrateMemberID(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_GMO_REGISTRATE_MEMBER_ID}`, params);
  }

  public apiPostFiberChargeCredit(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_CHARGE_CREDIT}`, params);
  }

  public apiPostUpdateFixedSchedule(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_UPDATE_FIXED_SCHEDULE}`, params);
  }

  public apiPostFiberChargedSmartpit(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_CHARGED_SMARTPIT}`, params);
  }

  public apiPostFiberYamatoCSVExport(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_EXPORT_YAMATO_CSV}`, params);
  }

  public apiPostFiberYamatoCSVImport(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_IMPORT_YAMATO_CSV}`, params);
  }

  public apiPostShippingdNotification(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_SHIPPING_NOTIFICATION}`, params);
  }

  public apiPostInstRemindNotification(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_INST_REMIND_NOTIFICATION}`, params);
  }

  public apiPostLineOpenNotification(params): Observable<any> {
    return this.post(`${Url.POST_FIBER_LINE_OPEN_NOTIFICATION}`, params);
  }

  public apiGetContractProductProperty(contract_product_id, property_key): Observable<any> {
    return this.get(`${Url.GET_CONTRACT_PRODUCT_PROPERTY}/${contract_product_id}/${property_key}`);
  }

  public apiPutContractProductProperty(params): Observable<any> {
    return this.put(Url.PUT_CONTRACT_PRODUCT_PROPERTY, params);
  }

  public apiGetContractProperty(contract_product_id, property_key): Observable<any> {
    return this.get(`${Url.GET_CONTRACT_PROPERTY}/${contract_product_id}/${property_key}`);
  }

  public apiPutContractProperty(params): Observable<any> {
    return this.put(Url.PUT_CONTRACT_PROPERTY, params);
  }

  public apiGetUserProperty(contract_product_id, property_key): Observable<any> {
    return this.get(`${Url.GET_USER_PROPERTY}/${contract_product_id}/${property_key}`);
  }

  public apiPutUserProperty(params): Observable<any> {
    return this.put(Url.PUT_USER_PROPERTY, params);
  }

  public apiGetTopUpTicketCampagins(): Observable<any> {
    return this.get(`${Url.GET_TOP_UP_CAMPAIGNS}`);
  }

  public apiGetTopUpTickets(): Observable<any> {
    return this.get(`${Url.GET_TOP_UP_TICKETS}`);
  }

  public apiPostGenerateTopUpTickets(params): Observable<any> {
    return this.post(`${Url.POST_GENERATE_TOP_UP_TICKETS}`, params);
  }

  public apiPostPublishEsimLink(params): Observable<any> {
    return this.post(`${Url.POST_PUBLISH_ESIM_LINK}`, params);
  }

  public apiPostReissueEsim(params): Observable<any> {
    return this.post(`${Url.POST_REISSUE_ESIM}`, params);
  }


  /* ----Activate---- */

  public fetchUsers(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url = `${environment.apiRoot}/api/long_esim/inactive_list`;
    console.log('AuthService::fetchUsers>>> : params=' + JSON.stringify(params));
    console.log('current_user.', current_user)
    console.log('current_user.auth_token', current_user.auth_token)
    return this.http.get(url, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${current_user ? current_user.auth_token : ''}`
      }),
      withCredentials: true
    });
  }

  public searchDate(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const httpParams = new HttpParams()
      .set('from_date', params.search_start_date)
      .set('to_date', params.search_end_date)
    const url = `${environment.apiRoot}/api/long_esim/inactive_list`;
    console.log('AuthService::searchDate>>> : params=' + JSON.stringify(params));
    return this.http.get(url, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${current_user ? current_user.auth_token : ''}`
      }),
      params: httpParams,
      withCredentials: true
    });
  }

  public editingNote(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url = `${environment.apiRoot}/api/long_esim/activation_note`;
    console.log('AuthService::editingNote>>> : params=' + JSON.stringify(params));
    return this.http.patch(url, params, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${current_user ? current_user.auth_token : ''}`
      }),
      withCredentials: true
    });
  }

  public activationStatus(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url = `${environment.apiRoot}/api/long_esim/activation_status`;
    console.log('AuthService::activationStatus>>> : params=' + JSON.stringify(params));
    return this.http.patch(url, params, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${current_user ? current_user.auth_token : ''}`
      }),
      withCredentials: true
    });
  }

  public getActivationStatus(params: any): Observable<Object> {
    console.log('Params received in getActivationStatus:', params);

    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    let httpParams = new HttpParams();
    if (params.from_date) {
      httpParams = httpParams.append('from_date', params.from_date);
    }
    if (params.to_date) {
      httpParams = httpParams.append('to_date', params.to_date);
    }
    if (params.status && params.status !== 'all') {
      httpParams = httpParams.append('activation_status[]', params.status);
    }
    const url = `${environment.apiRoot}/api/long_esim/inactive_list`;
    console.log('AuthService::fetchUsers>>> : params=' + JSON.stringify(params));
    return this.http.get(url, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${current_user ? current_user.auth_token : ''}`
      }),
      params: httpParams,
      withCredentials: true
    });
  }
  
  public levelMail(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url = `${environment.apiRoot}/api/long_esim/send_email`;
    console.log('AuthService::post : params=' + JSON.stringify(params));
    return this.http.post(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  public iijInfo(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url = `${environment.apiRoot}/api/long_esim/iij_info`;
    console.log('AuthService::post : url=' + url);
    console.log('AuthService::post : params=' + JSON.stringify(params));
    return this.http.post(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

  /*
   * API : MNP ***
   */
  public apiOrderRequestMNPNumber(params: any): Observable<Object> {
    return this.post(`${Url.ORDER_REQUEST_MNP_NUMBER}`, params);
  }

  public apiGetMNPCancelLineList(params: any): Observable<Object> {
    return this.get(`${Url.GET_MNP_CANCEL_LINE_LIST}/?cancel_month=${params.cancel_month}`);
  }
  
  /* ----Charge---- */
  public smartpitCvs(params: any): Observable<Object> {
    if (this.isLoggingIn() === false) {
      this.logout();
      return null;
    }
    const current_user = this.getloginUser();
    const url =  `${environment.apiRoot}/api/invoice/charge_unpaid_smartpit`
    console.log('AuthService::post : params=' + JSON.stringify(params));
    return this.http.post(url, params, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
      }),
      withCredentials: true
    });
  }

    /* ----lump-sum payment Charge----- */

    public lumpSumPayment(params: any): Observable<Object> {
      if (this.isLoggingIn() === false) {
        this.logout();
        return null;
      }
      const current_user = this.getloginUser();
      const url =  `${environment.apiRoot}/api/contract_product/single_payment_charge`
      console.log('AuthService::post : params=' + JSON.stringify(params));
      return this.http.post(url, params, {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + (current_user ? current_user.auth_token : '')
        }),
        withCredentials: true
      });
    }

  public apiSetProductsEndDates(params: any): Observable<Object> {
    return this.patch(`${Url.SET_PRODUCTS_END_DATES}`, params);
  }
}
