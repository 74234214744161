import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';

export class OrderRequestMnpNumberDialogData {
  contract_product_id: number;
  telno: string;
  mnp_name: string;
  mnp_name_kana: string;
  mnp_birth: string;
  mnp_sex: string;
}

@Component({
  selector: 'app-order-request-mnp-number-dialog',
  templateUrl: './order-request-mnp-number-dialog.component.html',
  styleUrls: ['./order-request-mnp-number-dialog.component.scss']
})
export class OrderRequestMnpNumberDialogComponent implements OnInit {

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OrderRequestMnpNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderRequestMnpNumberDialogData
  ) { }

  ngOnInit() {
  }

  public onOkClicked(event): void {
    const param = { contract_product_id: this.data.contract_product_id };
    this.auth_service.apiOrderRequestMNPNumber(param).subscribe(
      response => {
        this.dialogRef.close();
        this.snackBarSuccess("The MNP number issuance request has been submitted.", 'OK');
        console.log('apiOrderRequestMNPNumber response:', response);
      },
      error => {
        this.dialogRef.close();
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
        console.log('apiOrderRequestMNPNumber response:', error);
      }
    )
  }

  public onCancelClicked(event): void {
    this.dialogRef.close();
  }

  private snackBarSuccess(message: string, button: string): void {
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 5000;
    cfg.panelClass = ['notify_snackbar', 'success'];
    this.snackBar.open(message, button, cfg);
  }

  private snackBarError(message: string, button: string): void {
    console.log(`snackBarError ${message}, ${button}`);
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 10000;
    cfg.panelClass = ['notify_snackbar', 'error'];
    this.snackBar.open(message, button, cfg);
  }
}
