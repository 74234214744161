<div class="app-list-charges">
  <table class="operation" style="width: 100%">
    <tr>
      <td style="width: 150px; display: block; padding: 20px 0 0 15px;">
        <!-- <button mat-icon-button class="edit_icon" (click)="onRefreshList($event)">
          <mat-icon aria-label="Refresh list">refresh</mat-icon>
        </button> -->
        未払いのみ表示 :
        <mat-checkbox [checked]="pay_status === 'unpaid'" (change)="onUnpaidOnlyCheckChanged($event)"></mat-checkbox>
    </td>
    <td td style="width: 150px; display: block; padding: 10px 0 0 15px;">
      <!-- <button mat-icon-button class="edit_icon" (click)="onRefreshList($event)">
        <mat-icon aria-label="Refresh list">refresh</mat-icon>
      </button> -->
      支払済のみ表示 :
      <mat-checkbox [checked]="pay_status === 'paid'" (change)="onPaidOnlyCheckChanged($event)"></mat-checkbox>
    </td>

    <td >
      <table class="activate_month" >
        <tr style="display: flex;">
          <td class="title" style=" width: 65px; padding-top: 20px;">
            支払日 :&nbsp;
          </td>
          <td style="display: flex; padding-bottom: 45px;">
            <mat-form-field class="date_pick" style="width: 128px;">
              <input matInput type="date" [(ngModel)]="searchFromPayDate" [max]="maxStartDate"
                (change)="fromPayDate($event.target.value)" >
              <mat-hint>
                <div *ngIf="search_start_error" style="color: red">Invalid</div>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="date_pick" style="width: 128px ">
              <input matInput type="date" [(ngModel)]="searchToPayDate" [max]="maxStartDate" [min]="searchToPayDate"
                (change)="toPayDate($event.target.value)" >
              <mat-hint>
                <div *ngIf="search_end_error" style="color: red">Invalid</div>
              </mat-hint>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </td>


      <td style="text-align: right; align-content: right;">
        <table class="invoice_month"  >
          <tr>
            <td class="title" style="width: 36%; padding-bottom: 70px;">
              請求書発行月 :&nbsp;
            </td>
            <td class="date" style="width: 50%; padding: 0 0 50px 0;">
              <mat-form-field class="date_pick">
                <input matInput type="month" [(ngModel)]="invoice_month"
                  (change)="onInvoiceMonthChanged($event.target.value)">
                <mat-hint>
                  <div *ngIf="invoice_month_error" class="invoice_month_error">Invalid</div>
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
      <td style="width: 12%; padding: 0 0 35px 0;">
        <mat-form-field>
          <mat-select placeholder="支払い方法" (selectionChange)="onChangedPayMethod($event)">
            <mat-option *ngFor="let item of pay_methods" [value]="item.key">
              {{item.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <table>
          <tr>
            <td style="display: flex; padding:0 0 48px 20px; margin: 0;">
              <button (click)="onRefreshList($event)" mat-flat-button class="search-button" style="color: #fff;">
                検索
              </button>
            </td>
          </tr>
        </table>
      </td>
      <td style="text-align: right;">
        <table style="margin-left: auto;">
          <tr>
            <td>
              <mat-form-field style="min-width: 180px; margin-right: 20px;">
                <mat-select placeholder="督促状レベル" (selectionChange)="onChangedDemandLevel($event)">
                  <mat-option *ngFor="let item of demand_levels" [value]="item.key">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <button id="demand_send_button" mat-flat-button class="warn" style="margin-left: 25px;" [disabled]="demand_send_button_disabled"
                (click)="onClickSendDemand($event)">督促状の送信</button>
            </td>
            <td>
              <button id="charge_button" mat-flat-button class="warn" [disabled]="charge_button_disabled"
                (click)="onClickChargeDialog()">チャージ</button>
            </td>
          </tr>
        </table>

        <button mat-flat-button class="info-button" (click)="openSendDemandHelpDialog('send-demand.png')">info</button>
        <button id="release_button" mat-flat-button class="warn-release"
          (click)="onClickReleaseDialog()">解除</button>
        <button id="interruption_button" mat-flat-button class="warn"
          (click)="onClickInterruptionDialog()">中断</button>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="filter">
          <input matInput [(ngModel)]="current_filter" (keyup)="onFilterKeyUp($event.target.value)"
            placeholder="Filter" [disabled]="filter_key_up_disabled">
        </mat-form-field>
      </td>

      <div class="unpaid-checkbox-cotainer">
        <label class="unpaid-checkbox">
          未払い1件表示:
          <input type="checkbox" [(ngModel)]="isCheckedOne" (change)="applyFilter('unpaid_count_1')" [disabled]="loading"
            class="unpaid-box" />
        </label>
        <label class="unpaid-checkbox">
          未払い2件以上表示:
          <input type="checkbox" [(ngModel)]="isChecked" (change)="applyFilter('unpaid_count_2_or_more')" [disabled]="loading"
            class="unpaid-box" />
        </label>
      </div>
      <td>
        <button id="interruption_confirmation_button" mat-flat-button class="warn"
        (click)="onClickConfirmation()">中断確認</button>
      </td>

      <td class="charging-list-paginator" colspan="3">
        <mat-paginator class="charging-list-paginator" [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]"
          showFirstLastButtons></mat-paginator>
      </td>
    </tr>
  </table>
  <div class="list-invoices-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="is_selected">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [(ngModel)]="select_send_all" (change)="onSendAllCheckChanged($event)"
            (disabled)="select_send_all_disabled" [indeterminate]="select_send_all_indeterminate">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox [(ngModel)]="row.is_selected" (change)="onSendCheckChanged($event);">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract code </th>
        <td mat-cell *matCellDef="let element">
          <a class="contract_code" href="{{'/operation/contract?contract_id=' + element.contract_id}}"
            target="_blank">{{element.contract_code}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="invoice_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice code </th>
        <td mat-cell *matCellDef="let element">
          <a class="invoice_code"
            href="{{'/operation/invoice?contract_code=' + element.contract_code + '&invoice_month=' + invoice_month}}"
            target="_blank">{{element.invoice_code}}</a>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="pdf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
        <td mat-cell *matCellDef="let element">
          <a class="pdf" href="{{root_for_pdf + '/invoices/show/' + element.secure_random}}" target="_blank">show</a>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="mail_send_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求書 </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.mail_send_status === 0">
            <select name="send_status_select"
              (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET" selected>未送信</option>
              <option value="SENT">送信済</option>
            </select>
          </div>
          <div *ngIf="element.mail_send_status !== 0">
            <select name="send_status_select"
              (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET">未送信</option>
              <option value="SENT" selected>送信済</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="products">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 契約商品 </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="productsString(element.products)"></td>
      </ng-container>

      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ユーザー名 </th>
        <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="contract_end_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 契約<wbr/>終了日 </th>
        <td mat-cell *matCellDef="let element"> {{element.contract_end_date}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 支払<wbr/>方法 </th>
        <td mat-cell *matCellDef="let element"> {{element.pay_method}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 支払<wbr/>状況 </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.pay_status === 0 || element.pay_status === 'NOT_YET'">
            <select name="send_status_select" 
              (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET" selected>未払い</option>
              <option value="PAID">支払済</option>
            </select>
          </div>
          <div *ngIf="element.pay_status  !== 0 || element.pay_status === 'PAID' || element.pay_status === undefined">
            <select name="send_status_select"
              (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET">未払い</option>
              <option value="PAID" selected>支払済</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="unpaid_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 直近<wbr/>未払い<wbr/>件数 </th>
        <td mat-cell *matCellDef="let element"> {{ element.unpaid_count }} </td>
      </ng-container>

      
      <ng-container matColumnDef="interruption_confirmation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 中断回線数<wbr/>/回線数 </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.line_pause === undefined || element.line_pause === null ? '-' : element.line_pause }}/{{element.telno_count}} 
        </td>
      </ng-container>

      <ng-container matColumnDef="demand_level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 送信済<wbr/>督促Lv </th>
        <td mat-cell *matCellDef="let element"> {{element.demand_status}} </td>
      </ng-container>

      <ng-container matColumnDef="demand_sent_by">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 督促状<wbr/>送信者 </th>
        <td mat-cell *matCellDef="let element"> {{element.demand_send_user_id}} </td>
      </ng-container>

      <ng-container matColumnDef="demand_sent_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 督促<wbr/>送信日 </th>
        <td mat-cell *matCellDef="let element"> {{datePart(element.demand_sent_at)}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 支払日 </th>
        <td mat-cell *matCellDef="let element"> {{ processPayDate(element) }} </td>
      </ng-container>

      <ng-container matColumnDef="pay_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求<wbr/>金額<wbr/>合計 </th>
        <td mat-cell *matCellDef="let element"> {{yen(element.sub_total + element.sub_total_tax)}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_error">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求<wbr/>エラー </th>
        <td mat-cell *matCellDef="let element"> {{element.pay_error}} </td>
      </ng-container>

      <ng-container matColumnDef="invoice_note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 備考 </th>
        <td mat-cell *matCellDef="let element"> {{truncate(element.invoice_note, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="special_case">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 特別<wbr/>対応 </th>
        <td mat-cell *matCellDef="let element">
          <input type="checkbox" [(ngModel)]="element.special_case" (click)="onClickPutSpecialCase(element)" class="special_case_cb">
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
      <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
      <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
    </table>
  </div>
</div>
